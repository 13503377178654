import React from 'react';
import { Button } from '@mui/material';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DataContext } from './CategoriesContext';


export const CustomExport = ({ rows, columns }: any) => {
    const { data }: any = React.useContext(DataContext);

    const handleExport = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Add header row
        const headerRow = columns.map((col: { headerName: any; }) => col.headerName);
        worksheet.addRow(headerRow);

        // Add data rows
        rows.forEach((row: { [x: string]: any; }) => {
            const dataRow = columns.map((col: { field: string | number; }) => row[col.field]);

            worksheet.addRow(dataRow);
        });

        // Apply styles
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) { // Skip header row
                row.eachCell((cell, colNumber) => {
                    const column = columns[colNumber - 1];
                    if (column && column.field === 'oee_category') {
                        const rowData = rows[rowNumber - 2]; // Get corresponding row data
                        const categoryColor = data.find((d: any) => d.oee_category === rowData.oee_category);
                        if (categoryColor !== undefined) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: categoryColor.category_color.replace('#', '') }
                            };
                        }
                    }
                });
            }
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data-grid-export.xlsx');
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleExport}>
                Export
            </Button>
        </>
    );
};
