import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "fb19ff3d-f81f-4406-bc16-b6696858ddf3",
    authority:
      "https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477",
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    postLogoutRedirectUri: "/",
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const apiConfig = {
  scopes: [msalConfig.auth.clientId + "/.default"],
};
