//@ts-nocheck
import * as React from "react";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  useGridApiRef
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { AdminDataTableColumn } from "./adminDataTablesConfig";
import { useEffect, useState } from "react";

import Button from "src/components/layout/ui/button/Button";
import { endpoints } from "src/services/Endpoints";

import "./SEDataGrid.scss";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CONSTANTS from "../../configs/constants";
import { getNextPrefix } from "../../utils/getNextPrefix";

import { DateTimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sv'; // Swedish TimeZone
import 'dayjs/locale/de'; // German TimeZone
import 'dayjs/locale/es-us'; // United State TimeZone
import 'dayjs/locale/en-gb'; // United Kingdom TimeZone
import 'dayjs/locale/en'; // Default English Timezone
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { UserAccountContext } from "src/store/UserAccount";
import { Checkbox, OutlinedInput, useTheme } from "@mui/material";
import { DataContext } from "./CategoriesContext";
import { getEventAudit } from "src/services/EventsEndpoints";
import ModalAudit from "src/components/records-view/ModalAudit";
import { CustomExport } from "./CustomExport";
import TextInput from "src/components/TextField/TextField";
import NonEditText from "src/components/NonEditText/NonEditText";
import CustomAlert from "src/components/customAlert/customAlert";
// import CustomCell from "src/components/customCell/customCell";

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const localeFormats = {
  'en': 'MM/DD/YYYY hh:mm A',   // Default English
  'fr': 'DD/MM/YYYY HH:mm',     // French
  'pt': 'DD/MM/YYYY HH:mm',     // Portuguese
  'sv': 'YYYY-MM-DD HH:mm',     // Swedish
  'de': 'DD.MM.YYYY HH:mm',     // German
  'es-us': 'MM/DD/YYYY hh:mm A',// US Spanish
  'en-gb': 'DD/MM/YYYY HH:mm',  // UK English
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface TableProps {
  rows: any[];
  columns: AdminDataTableColumn[];
  section: string | undefined;
  loading: boolean;
  isRecords?: boolean;
}

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      No rows to display
    </Stack>
  );
}

function getStyles(name?: string, personName?: string[], theme?: Theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme?.typography.fontWeightRegular
        : theme?.typography.fontWeightMedium,
  };
}
type LocaleKey = (typeof locales)[number];

export default function SEDataGrid(props: TableProps): any {

  const theme = useTheme();
  const [error, setError] = useState("");
  const [rows, setRows] = useState(props.rows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const user = React.useContext(UserAccountContext);
  const { data } = React.useContext(DataContext);
  const [oeeCode, setOeeCode] = useState([]);
  const [cell, setCell] = useState([]);
  const [location, setLocation] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);

  const [rowModal, setRowModal] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [locale, setLocale] = React.useState<LocaleKey>('en');
  const [format, setFormat] = useState(localeFormats['en']);
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState({});
  const apiRef = useGridApiRef();

  useEffect(() => {
    const browserLocale = navigator.language || navigator.userLanguage;
    const shortLocale = browserLocale.toLowerCase() // Use the short form of the locale, e.g., 'en' from 'en-US'
    if (localeFormats[shortLocale]) {
      setLocale(shortLocale);
      setFormat(localeFormats[shortLocale]);
      dayjs.locale(shortLocale); // set the locale for dayjs
    }
    else {
      setLocale('en');
      setFormat('MM/DD/YYYY hh:mm A');
      dayjs.locale('en'); // set the locale for dayjs
    }
  }, []);

  const generateBoxStyle = (styles?) => {
    const styleObject = {};
    styles?.forEach(style => {
      const className = style?.oee_category.replace(/\s+/g, '_'); // Replace spaces with underscores
      styleObject[`.${gridClasses.cell}.${className}`] = {
        backgroundColor: style?.category_color,
      };
    });
    return styleObject;
  };

  const boxStyle = generateBoxStyle(data);

  useEffect(() => {
    const dynamicGet = endpoints['codes']["get"];
    dynamicGet(user).then((response) => {
      if (response) {
        const oeeCodeData = response.filter(val => val.is_active)
        setOeeCode(oeeCodeData)
      }
    });
  }, [user]);

  useEffect(() => {
    const dynamicGet = endpoints['cells']["get"];
    dynamicGet(user).then((response) => {
      if (response) {
        const cellFilter = response.filter(x => x.is_active === true)
        setCell(cellFilter)
      }
    });
  }, [user]);

  useEffect(() => {
    const dynamicGet = endpoints['types']["get"];
    dynamicGet(user).then((response) => {
      if (response) {
        const typeFilter = response.filter(x => x.is_active === true)
        setTypes(typeFilter)
      }
    });
  }, [user]);

  useEffect(() => {
    const dynamicGet = endpoints['locations']["get"];
    dynamicGet(user).then((response) => {
      if (response) {
        const LocationData = response.filter(x => x.is_active)
        setLocation(LocationData)
      }
    });
  }, [user]);

  useEffect(() => {
    // categories
    const dynamicGet = endpoints['categories']["get"];
    dynamicGet(user).then((response) => {
      if (response) {
        const categoriesData = response.filter(x => x.is_active)
        setCategories(categoriesData)
      }
    });
  }, [user])

  useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const handleCloseSnackbar = () => {
    setShow(false)
    setStatus({})
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (SK: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [SK]: { mode: GridRowModes.Edit } });
    setIsEditing(true);
    if (props?.isRecords) {
      // @ts-ignore
      const limitsPromise: any = endpoints[props.section || '']["limits"];
      const rowToEdit = rows.find((row) => row.SK === SK);
      limitsPromise(rowToEdit.location, rowToEdit.event_id).then((response: any) => {
        // response
      })
    }
  };

  const handleSaveClick = (SK: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [SK]: { mode: GridRowModes.View } });
    setIsEditing(false);
  };

  const handleDeleteClick = (SK: GridRowId) => () => {
    // find row to delete
    const rowToDelete = rows.find((row) => row.SK === SK);

    const dynamicDelete = endpoints[props.section || ""]["delete"];
    dynamicDelete(rowToDelete).then((response) => {
      // response
    });

    setRows(rows.filter((row) => row.SK !== SK));
  };

  const handleCancelClick = (SK: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [SK]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.SK === SK);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.SK !== SK));
    }
    setIsEditing(false);
  };

  const handleCellEditStart = React.useCallback(
    (params) => {
      // Get the cell element and focus it
      const cellElement = apiRef.current?.getCellElement(params.id, params.field);
      if (cellElement) {
        cellElement.focus();
      }
    },
    [apiRef]
  );

  const processRowUpdate = async (newRow: GridRowModel) => {

    let updatedRow = { ...newRow, isNew: false };
    const dynamicPut = endpoints[props.section || ""]["put"];

    try {
      const response = props?.isRecords ? await dynamicPut(newRow, props?.isRecords) : await dynamicPut(newRow);
      if (response?.status) {
        setShow(true)
        setStatus(response)
      }
      else {
        if (response && newRow.isNew) {
          const tmpSK = newRow.SK;
          newRow = { ...newRow, SK: response.SK, isNew: false };
          setRows(rows.map((row) => (row.SK === tmpSK ? newRow : row)));
          return newRow;
        } else {
          setRows(rows.map((row) => (row.SK === newRow.SK ? updatedRow : row)));
          return updatedRow;
        }
      }
    } catch (error) {
      setShow(true)
      setStatus(response)
      console.error("error updating row", error);
      // setError("Error updating row. Please try again." + error);
    }
  };

  const handleAddNewEntryClick = () => {
    const SK = randomId();
    const newRow: any = { isNew: true };
    columns.forEach((column) => {
      newRow[column.field] = "";
    });

    if (props.section === CONSTANTS.RESOURCES) {
      newRow['resource_id'] = getNextPrefix(props.rows.map(row => row.resource_id))
    }

    newRow.SK = SK;
    newRow.is_active = true;
    setRows((oldRows) => [newRow, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [SK]: { mode: GridRowModes.Edit, fieldToFocus: columns[0].field },
    }));
    setIsEditing(true);
  };

  const handleChangeCell = (event, params) => {
    const eventData = event.target.value.filter(x => x !== undefined)
    const updatedData = rows.map((row) =>
      row.SK === params.id ? { ...row, cell: eventData } : row
    );
    setRows(updatedData);
  }

  const handleChangeUserLocation = (event, params) => {
    const eventVlaue = event.target.value;
    const userLocation = eventVlaue.filter(x => x !== undefined)
    const updatedData = rows.map((row) =>
      row.SK === params.id ? { ...row, user_locations: userLocation } : row
    );
    setRows(updatedData);
  }

  const handleChangeCodeLocation = (event, params) => {
    const eventData = event.target.value.filter(x => x !== undefined)
    const updatedData = rows.map((row) =>
      row.SK === params.id ? { ...row, locations: eventData } : row
    );
    setRows(updatedData);
  }

  const handlelocationUser = (event, params) => {
    const updatedData = rows.map((row) =>
      row.SK === params.id ? { ...row, location: event.target.value } : row
    );
    setRows(updatedData);
  }

  const handleResourceCell = (event, params) => {
    const updatedData = rows.map((row) =>
      row.SK === params.id ? { ...row, cell: event.target.value } : row
    );
    setRows(updatedData);
  }

  const handleCategoryUser = (event, params) => {
    const updatedData = rows.map((row) =>
      row.SK === params.id ? { ...row, oee_category: event.target.value } : row
    );
    setRows(updatedData);
  }

  const handleTypeCode = (event, params) => {
    const updatedData = rows.map((row) =>
      row.SK === params.id ? { ...row, type_code: event.target.value } : row
    );
    setRows(updatedData);
  }

  const handleAuditClick = async (params) => {
    const eventId = params?.row?.event_id;
    const location = params?.row?.location;
    const getData = await getEventAudit(location, eventId);
    setOpen(true)
    setRowModal(getData)
  }

  const handleInputUserGid = (event, params) => {
    const updatedData = rows?.map((row) =>
      row.SK === params.id ? { ...row, gid: event.target.value } : row
    );
    setRows(updatedData);
  }

  const handleInputOEECode = (event, params) => {
    const updatedData = rows?.map((row) =>
      row.SK === params.id ? { ...row, oee_code: event.target.value } : row
    );
    setRows(updatedData);
  }

  const handleIsActiveCode = (event, params) => {
    const updatedData = rows?.map((row) =>
      row.SK === params.id ? { ...row, is_active: event.target.value } : row
    );
    setRows(updatedData);
  }

  const handleInputTypeDescription = (event, params) => {
    const updatedData = rows?.map((row) =>
      row.SK === params.id ? { ...row, type: event.target.value } : row
    );
    setRows(updatedData);
  }

  const cellColumn = {
    headerName: "Cell",
    field: "cell",
    editable: false,
    width: 200,
    renderCell: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return (
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={Array.isArray(params.row.cell) ? params.row.cell : [params.row.cell]}
              onChange={(event) => handleChangeCell(event, params)}
              input={<OutlinedInput label="cell" />}
              MenuProps={MenuProps}
            >
              {cell?.map((name) => (
                <MenuItem
                  key={name.cell}
                  value={name.cell}
                  style={getStyles(name.cell, params.row.cell, theme)}
                >
                  {name.cell}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
    },
  };

  const cellResource = {
    headerName: "Cell",
    field: "cell",
    editable: false,
    width: 200,
    renderCell: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return (
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={params.row.cell}
              onChange={(event) => handleResourceCell(event, params)}
            >
              {cell?.map((name) => (
                <MenuItem
                  key={name.cell}
                  value={name.cell}
                  style={getStyles(name.cell, params.row.cell, theme)}
                >
                  {name.cell}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
    }
  }
  const editActions = {
    field: "actiFons",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  }

  const cellUserLocation = {
    headerName: "User Locations",
    field: "user_locations",
    editable: false,
    width: 150,
    renderCell: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return (
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={Array.isArray(params?.row?.user_locations) ? params.row.user_locations : [params.row.user_locations]}
              onChange={(event) => handleChangeUserLocation(event, params)}
              input={<OutlinedInput label="cell" />}
              MenuProps={MenuProps}
            >
              {location?.map((name) => (
                <MenuItem
                  key={name.location}
                  value={name.location}
                  style={getStyles(name?.location, params?.row?.user_locations, theme)}
                >
                  {name.location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
    },
  }

  const cellLocationCode = {
    headerName: "Locations",
    field: "locations",
    editable: false,
    width: 150,
    renderCell: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return (
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={Array.isArray(params.row.locations) ? params.row.locations : [params.row.locations]}
              onChange={(event) => handleChangeCodeLocation(event, params)}
              input={<OutlinedInput label="cell" />}
              MenuProps={MenuProps}
            >
              {location.map((name) => (
                <MenuItem
                  key={name.location}
                  value={name.location}
                  style={getStyles(name.location, params.row.locations, theme)}
                >
                  {name.location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
    },
  }

  const locationUser = {
    headerName: "Location",
    field: "location",
    editable: false,
    width: 150,
    renderCell: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return (
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={params.row.location}
              label="location"
              onChange={(event) => handlelocationUser(event, params)}
            >
              {location?.map((name) => (
                <MenuItem
                  key={name.location}
                  value={name.location}
                  style={getStyles(name?.location, params?.row?.location, theme)}
                >
                  {name?.location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
    },
  }

  const columns: AdminDataTableColumn[] = [
    ...props.columns,
    editActions
  ];

  const columnsCode: AdminDataTableColumn[] = [
    {
      headerName: "OEE Code",
      field: "oee_code",
      type: "string",
      editable: false,
      width: 150,
      renderCell: (params: any) => {
        if (params?.row?.isNew) {
          return (
            <TextInput
              value={params?.row?.oee_code}
              onChange={(event) => handleInputOEECode(event, params)}
            />
          )
        }
        else {
          return (
            <NonEditText value={params?.row?.oee_code} />
          )
        }
      }
    },
    {
      headerName: "OEE Description",
      field: "oee_description",
      type: "string",
      editable: true,
      width: 200,
    },
    {
      headerName: "Type Description",
      field: "type",
      type: "string",
      editable: false,
      width: 150,
      renderCell: (params: any) => {
        if (params?.row?.isNew) {
          return (
            <TextInput
              value={params?.row?.type}
              onChange={(event) => handleInputTypeDescription(event, params)}
            />
          )
        }
      }
    },
    {
      headerName: "OEE Category",
      field: "oee_category",
      type: "singleSelect",
      editable: false,
      width: 200,
      renderCell: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return (
            <FormControl sx={{ m: 1, minWidth: 150 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={params?.row?.oee_category}
                label="category"
                onChange={(event) => handleCategoryUser(event, params)}
              >
                {categories?.map((name) => (
                  <MenuItem
                    key={name.oee_category}
                    value={name.oee_category}
                    style={getStyles(name.oee_category, params.row.oee_category, theme)}
                  >
                    {name.oee_category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
      }
    },
    cellColumn,
    cellLocationCode,
    {
      headerName: "Type Code",
      field: "type_code",
      type: "singleSelect",
      editable: false,
      width: 200,
      renderCell: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return (
            <FormControl sx={{ m: 1, minWidth: 150 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={params?.row?.type_code}
                label="category"
                onChange={(event) => handleTypeCode(event, params)}
              >
                {types?.map((name) => (
                  <MenuItem
                    key={name?.type_code}
                    value={name?.type_code}
                    style={getStyles(name?.type_code, params?.row?.type_code, theme)}
                  >
                    {name?.type_code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
      }
    },
    {
      headerName: "Is Active",
      field: "is_active",
      type: "boolean",
      editable: true,
      renderCell: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return (
            <Checkbox
              checked={params.value}
              onChange={(event) => {
                const newValue = event.target.checked;
                // Handle the change event, such as updating state or triggering an API call
                handleIsActiveCode(newValue)
                // Update the row data or handle it as required
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          );
        }
      },
    },
    editActions,
  ];

  const columnsRecords: AdminDataTableColumn[] = [
    ...props.columns,
    {
      headerName: "OEE Code",
      field: "oee_code",
      type: "string",
      editable: false,
      width: 200,
      renderCell: (params: any) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        return (
          <FormControl
            disabled={!isInEditMode}
            sx={{ m: 1, minWidth: 150 }}>
            <Select
              value={params.row.oee_code}
              onChange={(event) => handleChange(event, params)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }} >
              {oeeCode.map(val => {
                return (<MenuItem value={val.oee_code}>{val.oee_code}</MenuItem>)
              })}
            </Select>
          </FormControl>
        )
      }
    },
    {
      headerName: "OEE Description",
      field: "oee_description",
      type: "string",
      editable: false,
      width: 180,
      renderCell: (params: any) => {
        return (
          <p>{params?.row?.oee_description}</p>
        )
      }
    },
    {
      headerName: "OEE Category",
      field: "oee_category",
      type: "string",
      editable: false,
      width: 180,
      renderCell: (params: any) => {
        return (
          <p>{params?.row?.oee_category}</p>
        )
      }
    },
    {
      headerName: "Time Start",
      field: "time_start",
      type: "string",
      editable: false,
      width: 300,
      renderCell: (params: any) => {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        const dateTime = dayjs.utc(params?.row?.time_start).tz(timezone); // Parse as UTC
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                disabled={!isInEditMode}
                defaultValue={dateTime}
                onChange={(newValue) => handleTimeChangeStart(params.id, newValue)}
                format={format}
              />
            </DemoContainer>
          </LocalizationProvider>
        )
      },
    },
    {
      headerName: "Time Finish",
      field: "time_finish",
      type: "string",
      editable: false,
      width: 300,
      renderCell: (params: any) => {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        const dateTime = dayjs.utc(params?.row?.time_finish).tz(timezone); // Parse as UTC
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                disabled={!isInEditMode}
                defaultValue={dateTime}
                onChange={(newValue) => handleTimeChangeFinish(params.id, newValue)}
                format={format}
              />
            </DemoContainer>
          </LocalizationProvider>
        )
      },
    },
    {
      headerName: "Audit",
      // field: "time_finish",
      // type: "string",
      editable: false,
      width: 150,
      renderCell: (params: any) => {
        return (
          <Button
            label="Audit"
            onClick={() => handleAuditClick(params)}
          />
        )
      },
    },
    editActions,
  ]

  const columnsUsers: AdminDataTableColumn[] = [
    {
      headerName: "GID",
      field: "gid",
      type: "string",
      width: 120,
      renderCell: (params: any) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode && params?.row?.isNew) {
          return (
            <TextInput
              value={params?.row?.gid}
              onChange={(event) => handleInputUserGid(event, params)}
            />
          )
        }
        else {
          return (
            <NonEditText value={params?.row?.gid} />
          )
        }
      }
    },
    ...props.columns.slice(0, 4),
    cellUserLocation,
    locationUser,
    {
      headerName: "Is Active",
      field: "is_active",
      type: "boolean",
      editable: true,
    },
    editActions,
  ];

  const columnResource: AdminDataTableColumn[] = [
    ...props.columns,
    locationUser,
    cellResource,
    {
      headerName: "Is Active",
      field: "is_active",
      type: "boolean",
      editable: true,
      width: 70,
    },
    editActions
  ]

  const modelAudit: AdminDataTableColumn[] = [
    ...props.columns
  ]

  const typeCodes: AdminDataTableColumn[] = [
    {
      headerName: "Type Code",
      field: "type_code",
      type: "string",
      editable: false,
      width: 120,
      renderCell: (params: any) => {
        if (params.row.isNew) {
          return (
            <TextInput
              value={params?.row?.type_code}
              onChange={(event) => handleInputTypeCode(event, params)}
            />
          )
        }
        else {
          return (
            <NonEditText value={params?.row?.type_code} />
          )
        }
      }
    },
    ...props.columns,
    editActions
  ]

  const columnCell: AdminDataTableColumn[] = [
    {
      headerName: "Cell",
      field: "cell",
      type: "string",
      editable: false,
      width: 120,
      renderCell: (params: any) => {
        if (params.row.isNew) {
          return (
            <TextInput
              value={params?.row?.cell}
              onChange={(event) => handleInputTypeCell(event, params)}
            />
          )
        }
        else {
          return (
            <NonEditText value={params?.row?.cell} />
          )
        }
      }
    },
    ...props.columns,
    editActions
  ]

  const columnLocation: AdminDataTableColumn[] = [
    {
      headerName: "Location",
      field: "location",
      type: "string",
      editable: false,
      width: 120,
      renderCell: (params: any) => {
        if (params?.row?.isNew) {
          return (
            <TextInput
              value={params?.row?.location}
              onChange={(event) => handleInputLocation(event, params)}
            />
          )
        }
        else {
          return (
            <NonEditText value={params?.row?.location} />
          )
        }
      }
    },
    ...props.columns,
    editActions
  ]

  const handleInputTypeCode = (event?, params?) => {
    const updatedData = rows?.map((row) =>
      row.SK === params.id ? { ...row, type_code: event.target.value } : row
    );
    setRows(updatedData);
  }

  // const handleInputResourceId = (event?, params) => {
  //   const updatedData = rows?.map((row) =>
  //     row.SK === params.id ? { ...row, resource_id: event.target.value } : row
  //   );
  //   setRows(updatedData);
  // }

  const handleInputTypeCell = (event, params) => {
    const updatedData = rows?.map((row) =>
      row.SK === params.id ? { ...row, cell: event.target.value } : row
    );
    setRows(updatedData);
  }

  const handleInputLocation = (event, params) => {
    const updatedData = rows?.map((row) =>
      row.SK === params.id ? { ...row, location: event.target.value } : row
    );
    setRows(updatedData);
  }

  const handleTimeChangeStart = (id, newValue) => {
    const updatedData = rows.map((row) =>
      row.SK === id ? { ...row, time_start: newValue.toISOString().slice(0, 16) + 'Z' } : row
    );
    setRows(updatedData);
  };

  const handleTimeChangeFinish = (id, newValue) => {
    const updatedData = rows.map((row) =>
      row.SK === id ? { ...row, time_finish: newValue.toISOString().slice(0, 16) + 'Z' } : row
    );
    setRows(updatedData);
  };

  const handleChange = (event, params) => {
    const newData = oeeCode.find(val => val.oee_code === event.target.value)
    const updatedData = rows?.map((row) =>
      row.SK === params.id ? {
        ...row,
        oee_code: event.target.value,
        oee_description: newData?.oee_description,
        oee_category: newData?.oee_category,
      } : row
    );
    setRows(updatedData);
  };

  const handleCustomCellColor = (params: GridCellParams<any, any, number>) => {
    if (params.field === 'oee_category') {
      for (let i = 0; i < data.length; i++) {
        if (data[i].oee_category === params.value) {
          const className = params.value.replace(/\s+/g, '_');
          return className;
        }
      }
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="se-data-grid__toolbar">
        {props.section !== "events" && (
          <Button
            disabled={isEditing}
            label="Add"
            onClick={handleAddNewEntryClick}
          />
        )}
        {props.section === "codes" ?
          <>
            <CustomExport
              rows={rows}
              columns={sectionList(props?.section)}
            />
          </>
          :
          <GridToolbarExport
            printOptions={{ disableToolbarButton: true }}
            className="se-button se-button--secondary se-data-grid__export-button"
          />
        }
      </GridToolbarContainer>
    );
  }

  const sectionList = (value) => {
    switch (value) {
      case 'events':
        return columnsRecords;
      case 'codes':
        return columnsCode;
      case 'users':
        return columnsUsers;
      case 'modalAudit':
        return modelAudit;
      case 'resources':
        return columnResource;
      case 'types':
        return typeCodes;
      case 'locations':
        return columnLocation;
      case 'cells':
        return columnCell;
      default:
        return columns
    }
  }

  return (
    <div className="se-data-grid">
      <CustomAlert
        snackbarOpen={show}
        handleCloseSnackbar={handleCloseSnackbar}
        error={error}
        status={status.status}
      />
      {
        data && <Box sx={boxStyle}>
          <DataGrid
            rows={rows}
            columns={sectionList(props?.section)}
            getCellClassName={handleCustomCellColor}
            getRowId={(row: any) => row.SK}
            apiRef={apiRef}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onCellEditStart={handleCellEditStart}
            onProcessRowUpdateError={(params) => {
              setError("Error updating row. Please try again.");
              setShow(true)
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel, columns },
            }}
            slots={{
              noRowsOverlay: NoRowsOverlay,
              toolbar: CustomToolbar,
            }}
            disableRowSelectionOnClick
            isRowSelectable={false}
            loading={props.loading}
          />
        </Box>
      }
      <ModalAudit
        rows={rowModal}
        loading={props.loading}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}
