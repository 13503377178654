import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useContext } from "react";
import { ResourceContext } from "../../store/ResourceContext";

export const WeekSelector = () => {
    const resourceContext = useContext(ResourceContext);

    if (!resourceContext) {
        throw new Error("ResourceContext is null");
    }

    const {
        startWeekDate,
        endWeekDate,
        setEndWeekDate,
        setStartWeekDate,
    } = resourceContext;


    return (
        <div className='resource-selection-week-display'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Start Week"
                    value={startWeekDate}
                    format='DD/MM/YYYY'
                    onChange={(newValue) => { setStartWeekDate(newValue); }}
                />
                <DatePicker
                    label="End Week"
                    value={endWeekDate}
                    format='DD/MM/YYYY'
                    onChange={(newValue) => { setEndWeekDate(newValue); }}
                />
            </LocalizationProvider></div>



    );
};
