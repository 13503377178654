import React from 'react';

const URL = require('../assets/images/calculating-oee-image.webp');

export default function About() {
    return (
        <div>
            <h1>OEE Tracking System</h1>
            <p>This app is used to track OEE for factories in a number of business areas. It allows the collection of job data and then automatically calculating the OEE of the selected resources. Data is also exported to a Tableau dashboard for reporting.</p>
            <p>&nbsp;</p>
            <p>For any issues, please use the “Report Issues” link at the bottom right of the page.&nbsp;</p>
            <h4>OEE Definition</h4>
            <p>This is the OEE Tracking System. OEE stands for Overall Equipment Effectiveness and is derived from the following, which is seen as the preferred calculation;</p>
            <p>&nbsp;</p>
            <p style={{ textAlign: 'center' }}> <img src={URL} alt="OEE Calc" style={{ width: '459px', height: '76px' }} /></p>
            <p style={{ textAlign: 'center' }}>OEE is calculated by multiplying the three OEE factors: Availability, Performance, and Quality.</p>
            <p style={{ textAlign: 'center' }}>
                See<a target="_blank" rel="noopener noreferrer" href="https://www.oee.com/calculating-oee/">https://www.oee.com/calculating-oee/</a>for detailed information
            </p>
            <p>&nbsp;</p>
            <h4>OEE Implemention in this app</h4>
            <p>As per the above formula with the individual parts sourced as below;</p>
            <p>
                <strong><u>Availability</u></strong>
            </p>
            <p>
                <i>Productive Time</i>is where the Code OEE Category Group is “Planned” or “Productive”.
            </p>
            <p>
                <i>Unplanned Time</i>is all other OEE Category Groups
            </p>
            <p>
                <strong>Calculation</strong>
            </p>
            <p>Productive Time / (Productive Time+Unplanned Time)</p>
            <p>&nbsp;</p>
            <p>
                <strong><u>Performance</u></strong>
            </p>
            <p>
                <strong>Calculation</strong>
            </p>
            <p>Always 1 in the current version of this app</p>
            <p>&nbsp;</p>
            <p>
                <strong><u>Quality</u></strong>
            </p>
            <p>Only codes with a Work Order added are used in calculation</p>
            <p>
                <strong>Calculation</strong>
            </p>
            <p>Number of “Good” Work Order codes / Total number of Work Order codes</p>

        </div>
    );
}
