import { getData, putData, deleteData } from "./Api";
import { CONSTANTS } from "../configs/constants";

export const eventsEndpoints = {
  get: async (user: any) => await getEvents(user),
  put: async (data: any, isRecords: boolean = false) => await putEvent(data, isRecords),
  delete: async (data: any) => await deleteEvent(data),
  limits: async (locationId: string, eventId: string) => await getLimits(locationId, eventId),
};

export interface Event {
  [x: string]: any;
  cal_week?: string | null;
  duration?: string | null;
  event_description: string | null;
  event_id?: string | null;
  good_bad: string;
  location: string;
  oee_category: string;
  oee_color?: string | null;
  oee_code: string;
  oee_description: string;
  resource: string;
  resource_description: string;
  resource_id: string;
  time_finish: string;
  time_start: string;
  wo_category: string | null;
  wo_number: string | null;
  status?: string | null;
  is_active: string | null;
  isStillRunning?: boolean;
}

export async function getEvents(user: any) {
  return null;
}

export async function getEvent(location: string, eventId?: string) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    location +
    "/" +
    CONSTANTS.EVENTS +
    "/" +
    eventId;

  return await getData(url);
}

export async function getEventAudit(location: string, eventId?: string) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    location +
    "/" +
    CONSTANTS.EVENTS +
    "/" +
    eventId +
    "/" +
    CONSTANTS.AUDITS;
  return await getData(url);
}

export async function putEvent(data: Event, isRecords: boolean = false) {
  const url = isRecords ?
    `${process.env.REACT_APP_BACKEND_URL}locations/${data.location}/events/${data.event_id}`
    : process.env.REACT_APP_BACKEND_URL + CONSTANTS.EVENTS;

  return await putData(url, data);
}

export async function deleteEvent(data: any) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    data.location +
    "/" +
    CONSTANTS.EVENTS +
    "/" +
    data.event_id;

  return await deleteData(url);
}

export async function getEventsByLocation(
  location: string,
  weekStart: string,
  weekEnd: string
) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    location +
    "/" +
    CONSTANTS.EVENTS +
    "?start=" +
    weekStart +
    "&end=" +
    weekEnd;

  return await getData(url);
}

export async function getEventsByWeekAndResource(
  location: string,
  week: string,
  resourceId: string
) {
  const data = await getEventsByLocation(location, week, week);

  if (data && data.length > 0) {
    return data?.filter((event: Event) => event.resource_id === resourceId);
  } else return [];
}


export async function getLimits(locationID: string, eventID: string) {
  const url = `${process.env.REACT_APP_BACKEND_URL}${CONSTANTS.LOCATIONS}/${locationID}/${CONSTANTS.EVENTS}/${encodeURI(eventID)}/limits`;
  return await getData(url);
}
