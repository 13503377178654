import RecordsView from "src/components/records-view/RecordsView";
import SelectedLocation from "src/components/location-selection/LocationSelection";
import { ResourceProvider } from "src/store/ResourceContext";
import { WeekSelector } from "../components/week-selector/WeekSelector";

export default function Records() {
  return (
    <ResourceProvider>
      <div className="layer">
        <div className="layout-flex">
          <SelectedLocation />
          <WeekSelector />
        </div>
        <RecordsView />
      </div>
    </ResourceProvider>
  );
}
