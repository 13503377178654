import { useContext, useEffect, useState } from "react";

import { ResourceContext } from "src/store/ResourceContext";
import { getLatestEventByResource } from "src/services/ResourcesEndpoints";

import { Event } from "src/services/EventsEndpoints";
import ActionButton from "src/components/action-button/ActionButton";

import "./CodeControl.scss";
import CurrentCodeDisplay from "../current-code-display/CurrentCodeDisplay";

import { EVENT_ACTIONS } from "./../../configs/constants";

function CodeControl() {
  const resourceContext = useContext(ResourceContext);

  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }

  const {
    selectedLocation,
    selectedResource,
    startButtonStatus,
    setStartButtonStatus,
    stopButtonStatus,
    setStopButtonStatus,
    toogleDialog,
    setToogleDialog,
    setEventDialog,
    // currentWeekNumber,
    currentWeekStartDate,
  } = resourceContext;

  const [event, setEvent] = useState<Event>({
    cal_week: null,
    duration: "",
    event_description: "",
    event_id: "",
    good_bad: "",
    location: "",
    oee_category: "",
    oee_code: "",
    oee_description: "",
    resource: "",
    resource_description: "",
    resource_id: "",
    time_finish: "",
    time_start: "",
    wo_category: "",
    wo_number: "",
    status: "",
    is_active: "True",
  });

  function setButtonsState() {
    if (!selectedResource) return;
    // get latest event
    getLatestEventByResource(
      selectedLocation,
      selectedResource.resource_id
    ).then((latestEvent) => {
      // if latestEvent exists and doesn't have and end date, the code is still running
      if (latestEvent && latestEvent?.time_finish === latestEvent?.time_start && latestEvent.resource_id) {
        setEvent(latestEvent);
        setStartButtonStatus(false);
        setStopButtonStatus(true);
      } else {

        setEvent((prevEvent) => ({
          ...prevEvent,
          location: selectedLocation,
          resource: selectedResource.resource,
          resource_description: selectedResource.resource_description,
          resource_id: selectedResource.resource_id,
          time_start: latestEvent
            ? latestEvent.time_finish
            : currentWeekStartDate,
          time_finish: latestEvent
            ? latestEvent.time_finish
            : currentWeekStartDate,

        }));
        setStartButtonStatus(true);
        setStopButtonStatus(false);
      }
    });
  }

  function handleToogleDialog(action: string) {
    setToogleDialog(!toogleDialog);
    event.status = action;
    setEventDialog(event);
  }

  useEffect(() => {
    async function fetchData() {
      setButtonsState();
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, selectedResource, toogleDialog]);

  return selectedLocation && selectedResource ? (
    <>
      <div className="code-control">
        {stopButtonStatus ? (
          <CurrentCodeDisplay event={event} />
        ) : (
          "no code running"
        )}

        <div className="code-control__buttons">
          <ActionButton
            text="Start"
            isActive={startButtonStatus}
            onClick={() => handleToogleDialog(EVENT_ACTIONS.START)}
          ></ActionButton>
          <ActionButton
            text="Stop"
            isActive={stopButtonStatus}
            onClick={() => handleToogleDialog(EVENT_ACTIONS.STOP)}
          ></ActionButton>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

export default CodeControl;

