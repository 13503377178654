import { getData, putData, deleteData } from "./Api";
import { CONSTANTS } from "../configs/constants";

export const cellsEndpoints = {
  get: async () => await getCells(),
  put: async (data: any) => await putCells(data),
  delete: async (data: string) => await deleteCells(data),
};

export async function getCells() {
  const url = process.env.REACT_APP_BACKEND_URL + CONSTANTS.CELLS;
  return await getData(url);
}

export async function putCells(data: any) {
  const url =
    process.env.REACT_APP_BACKEND_URL + CONSTANTS.CELLS + "/" + data.cell;

  return await putData(url, data);
}

export async function deleteCells(data: any) {
  const url = process.env.REACT_APP_BACKEND_URL + CONSTANTS.CELLS + "/";
  return await deleteData(url);
}
