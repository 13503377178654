import { useContext, useEffect } from "react";
import { ResourceContext } from "../../store/ResourceContext";
import { UserAccountContext } from "src/store/UserAccount";
// import { getLocation } from "src/services/LocationsEndpoints";

import "./LocationSelection.scss";

function LocationSelection() {
  const locationSessionStorage = "selectedLocation";

  const user = useContext(UserAccountContext);
  const userLocations = user.user_locations;

  const resourceContext = useContext(ResourceContext);

  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }

  const { selectedLocation, setSelectedLocation } = resourceContext;

  const handleLocationChange = (event: { target: { value: string } }) => {
    const newLocation = event.target.value;
    setSelectedLocation(newLocation);
    sessionStorage.setItem(locationSessionStorage, newLocation);
  };

  // set initial location
  useEffect(() => {
    const storedLocation = sessionStorage.getItem(locationSessionStorage);

    // check if there is an stored location and if it is on the possible values for userLocations
    if (storedLocation !== null && userLocations.includes(storedLocation)) {
      setSelectedLocation(storedLocation);
    } else {
      setSelectedLocation(userLocations[0]);
      sessionStorage.setItem(locationSessionStorage, userLocations[0]);
    }
  }, [setSelectedLocation, userLocations]);

  useEffect(() => {
    async function fetchData() {
    }
    fetchData();
  }, [selectedLocation]);

  return (
    <select
      className="select-location"
      name="locations"
      id="locations"
      value={selectedLocation}
      onChange={handleLocationChange}
    >
      {userLocations?.map((location: string) => (
        <option key={location} value={location}>
          {location}
        </option>
      ))}
    </select>
  );
}

export default LocationSelection;
