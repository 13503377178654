import { Outlet } from "react-router-dom";
import SideNavbar from "src/components/layout/ui/sidenavbar/SideNavbar";

import "./../index.scss";
import { DataProvider } from "../features/admin-data-tables/CategoriesContext";

export default function Layout(): React.JSX.Element {

  return (
    <>
      <DataProvider>
        <div className="layout">
          <SideNavbar />
          <div className="layout__content">
            <Outlet />
          </div>
        </div>
      </DataProvider>
    </>
  );
}
