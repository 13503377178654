export const appTitle = "Tracking System";
export const version = "1.0";
export const headerLogo = "./../assets/images/SE_Logo_Color_RGB.svg";
export const SELink = "https://www.siemens-energy.com/global/en/home.html";
export const brand = "© Siemens Energy";

interface MenuItem {
  label: string;
  icon: string;
  url: string;
  children?: MenuItem[];
}

export const sideNavbar: MenuItem[] = [
  {
    label: "Monitoring",
    icon: "",
    url: "/",
  },
  {
    label: "Records",
    icon: "",
    url: "/records",
  },
  {
    label: "Settings",
    icon: "",
    url: "/settings",
    children: [
      {
        label: "Users",
        icon: "",
        url: "users",
      },
      {
        label: "Resources",
        icon: "",
        url: "resources",
      },
      {
        label: "OEE Categories",
        icon: "",
        url: "categories",
      },
      {
        label: "Codes",
        icon: "",
        url: "codes",
      },
      {
        label: "Type Codes",
        icon: "",
        url: "types",
      },
      {
        label: "Cells Type",
        icon: "",
        url: "cells",
      },
      {
        label: "Locations",
        icon: "",
        url: "locations",
      },
      {
        label: "Location formats",
        icon: "",
        url: "formats",
      },
    ],
  },
  {
    label: "About",
    icon: "",
    url: "/about",
  },
];

export const footerLinks = [
  {
    title: "Report Issue",
    href: "",
  },
];
