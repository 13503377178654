

export async function addAppstoreUserAccess(gid: string) {
  const appId = 10023;

  // set body for the request
  const url =
    "https://appstore.edaa.siemens-energy.cloud/api/log?" + appId + "&" + gid;

  try {
    await fetch(url);
    // handle the response data
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
