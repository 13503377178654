import { Event } from "../../services/EventsEndpoints";

export type TimeSlot = {
    startTime: Date;
    endTime: Date;
    event: Event | null;
};

export type WeekDay = "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";

export type Schedule = {
    [key in WeekDay]: TimeSlot[];
};

export const daysOfTheWeek: WeekDay[] = [
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT",
    "SUN",
];
