import { useContext, useEffect } from "react";
import "./CurrentWeekDisplay.scss";
import { ResourceContext } from "src/store/ResourceContext";


function getWeekNumberwithDate(dateString: string): number {
  // Parse the date string into a Date object
  const months: { [key: string]: number } = {
    Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
    Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
  };
  const [day, month, year] = dateString.split('/');
  const date = new Date(Number(year), months[month], Number(day));

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = date.getUTCDay();

  // Set the date to Thursday of the current week
  const thursday = new Date(date);
  thursday.setUTCDate(date.getUTCDate() + 4 - (dayOfWeek || 7));

  // Get the year and the start of the year
  const yearStart = new Date(Date.UTC(thursday.getUTCFullYear(), 0, 1));

  // Calculate the week number
  const weekNumber = Math.ceil((((thursday.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

  return weekNumber;
}

// function getWeekNumber(date: Date): number {
//   const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
//   const daysSinceFirstDayOfYear =
//     (date.valueOf() - firstDayOfYear.valueOf()) / 86400000;
//   const weekNumber = Math.ceil(
//     (daysSinceFirstDayOfYear + firstDayOfYear.getDay() + 1) / 7
//   );
//   return weekNumber;
// }

export function getStartDateOfWeek(date: Date): Date {
  const dayOfWeek = date.getDay();
  const daysSinceMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  const startDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate() - daysSinceMonday
  );
  return startDate;
}

// function getEndDateOfWeek(date: Date): Date {
//   const dayOfWeek = date.getDay();
//   const daysUntilSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
//   const endDate = new Date(
//     date.getUTCFullYear(),
//     date.getUTCMonth(),
//     date.getUTCDate() + daysUntilSunday
//   );

//   return endDate;
// }

// function formatDate(date: Date): string {
//   return date
//     .toLocaleDateString("en-UK", {
//       day: "2-digit",
//       month: "short",
//       year: "numeric",
//     })
//     .replace(",", "")
//     .replace(/ /g, "/");
// }

function getYearFromDate(dateString: string): number {
  // Split the date string by '/'
  const parts = dateString.split('/');

  // The year is the third part in the string
  const year = Number(parts[2]);

  return year;
}


function formatedWeekNumber(date: string) {
  // const currentYear = date.getFullYear();
  const currentYear = getYearFromDate(date)
  const currentWeekNumber = getWeekNumberwithDate(date);

  return `${currentYear}-${currentWeekNumber < 10 ? "0" : ""
    }${currentWeekNumber}`;
}

function CurrentWeekDisplay() {
  const resourceContext = useContext(ResourceContext);

  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }

  const { setCurrentWeekNumber, setCurrentWeekStartDate, selectedWeek } = resourceContext;

  const dateString: string = selectedWeek?.endDate;
  const weekNumber: number = getWeekNumberwithDate(dateString);

  useEffect(() => {
    // Their is delay from APi response to avoid add SetTimeout function
    setTimeout(() => {
      const currentDate = new Date(new Date().toISOString());
      setCurrentWeekNumber(formatedWeekNumber(dateString));
      const startDate = getStartDateOfWeek(currentDate);
      const formatDate = startDate.toISOString().slice(0, 16) + "Z";
      setCurrentWeekStartDate(formatDate);
    }, 1500);

  }, [setCurrentWeekNumber, setCurrentWeekStartDate, dateString]);

  return (
    <div className="current-week-display">
      <p className="current-week-display__week-number">
        Week Number {weekNumber}
      </p>
      <div>
        <p> {selectedWeek?.startDate} - {selectedWeek?.endDate} </p>
      </div>
    </div>
  );
}

export default CurrentWeekDisplay;
