import { getData, putData, deleteData } from "./Api";
import { CONSTANTS } from "../configs/constants";

export interface Category {
  oee_category: string;
  category_color: string;
  is_active: boolean;
}

export const categoriesEndpoints = {
  get: async () => await getCategories(),
  put: async (data: any) => await putCategories(data),
  delete: async (data: string) => await deleteCategories(data),
};

export async function getCategories(isActive = false) {
  let url = process.env.REACT_APP_BACKEND_URL + CONSTANTS.CATEGORIES;

  const categories = await getData(url);

  if (isActive) {
    return categories.filter((category: Category) => category.is_active);
  }
  return categories;
}

export async function putCategories(data: any) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.CATEGORIES +
    "/" +
    data.oee_category;

  return await putData(url, data);
}

export async function deleteCategories(data: any) {
  // transform user into api format
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.CATEGORIES +
    "/" +
    data.oee_category;

  return await deleteData(url);
}

// -------------- ADDITIONAL ENDPOINTS ----------------
export async function getCategoriesColor(categories: string[]) {
  const categoriesArray = await getCategories();
  const categoriesMap: Record<string, string> = {};

  categoriesArray?.forEach((category: Category) => {
    categoriesMap[category.oee_category] = category.category_color;
  });

  const categoriesWithColor = categories.map((category) => {
    const color = categoriesMap[category];
    return { category, color };
  });

  return categoriesWithColor;
}
