import { Alert, AlertTitle, Button, Snackbar } from '@mui/material';
import './CustomAlert.scss'; // Make sure to create this CSS file

type ICustomALert = {
    snackbarOpen: boolean,
    handleCloseSnackbar: () => void,
    error: string,
    status: number
}
const CustomAlert = ({
    snackbarOpen,
    handleCloseSnackbar,
    error,
    status
}: ICustomALert) => {
    return (
        <div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={60000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                classes={{ root: 'custom-snackbar' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    className="custom-alert"
                    action={
                        <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                            Close
                        </Button>
                    }
                >
                    <AlertTitle>Error</AlertTitle>
                    {status && (<p>status : {status}</p>)}
                    {error}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default CustomAlert;
