import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { getCategories } from 'src/services/CategoriesEndpoints';

// Define the shape of the data you expect from the API
interface Data {
    oee_category: string;
    category_color: string;
}

// Define the context state
interface DataContextState {
    data: any | null;
    loading: boolean;
    error: Error | null;
}

// Create the context with a default value
export const DataContext = createContext<DataContextState>({
    data: null,
    loading: true,
    error: null,
});

// Define the provider's props type
interface DataProviderProps {
    children: ReactNode;
}

// Create a provider component
export const DataProvider = ({ children }: DataProviderProps) => {
    const [data, setData] = useState<Data[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = getCategories();
            response.then((result: any) => {
                setData(result);
                setLoading(false)
            })
                .catch((err: any) => {
                    console.error(err)
                    setError(err)
                })
        };
        fetchData();
    }, []);

    return (
        <DataContext.Provider value={{ data, loading, error }}>
            {children}
        </DataContext.Provider>
    );
};
