import "./Footer.scss";

interface FooterProps {
  version: string;
  brand: string;
}

export default function Footer({ version, brand }: FooterProps) {
  return (
    <footer className="footer">
      <div>
        {brand}, {new Date().getUTCFullYear()}
      </div>
      <div className="footer__links">
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/05ff8d36-17ac-4a2e-8d12-e5f87802fbe5?hidenavbar=true&Lookup=30"
          >
            Report Issue
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/43139f05-34c9-4f0b-85b8-746a2137f6ca?hidenavbar=true&Lookup=30"
          >
            View all issues
          </a>
        </div>
        <div>{`Version ` + version}</div>
      </div>
    </footer>
  );
}
