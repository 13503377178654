import "./Button.scss";

interface ButtonProps {
  label?: string;
  icon?: string;
  isPrimary?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export default function Button({
  label,
  isPrimary = true,
  disabled = false,
  onClick,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      className={`se-button ${
        isPrimary ? "se-button--primary" : "se-button--secondary"
      }`}
      onClick={onClick}
    >
      {/* <i></i> */}
      <span>{label}</span>
    </button>
  );
}
