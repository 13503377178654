import { GridColDef, GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import CONSTANTS, { GROUP } from "./../../configs/constants";
import { ROLES } from "./../../configs/constants";
import { MuiColorInput, MuiColorInputColors } from "mui-color-input";
import React from "react";
import NonEditText from "src/components/NonEditText/NonEditText";

export type AdminDataTableSection =
  | typeof CONSTANTS.USERS
  | typeof CONSTANTS.CODES
  | typeof CONSTANTS.RESOURCES
  | typeof CONSTANTS.LOCATIONS
  | typeof CONSTANTS.FORMATS
  | typeof CONSTANTS.CATEGORIES
  | typeof CONSTANTS.TYPES
  | typeof CONSTANTS.CELLS;

export type AdminDataTableConfig = {
  [key in AdminDataTableSection]: AdminDataTableContent;
};

export type AdminDataTableContent = {
  title: string;
  description?: string;
  buttonAddLabel?: string;
  columns: AdminDataTableColumn[];
};

export type AdminDataTableColumn = GridColDef & {
  valueOptions?: {
    value: string;
    label: string;
  }[];
  isCellEditable?: any;
};

//@ts-ignore
// const renderColorPickerEditInputCell: GridColDef["renderCell"] = (params) => {
//     return <ColorPickerEditInputCell {...params} />;
// };

const ColorPickerEditInputCell: React.FC<any> = (props: GridRenderCellParams<any, string> & { isEditMode?: boolean }) => {
  const { id, value, field, isEditMode } = props;
  const apiRef = useGridApiContext();
  const handleChange = (value: string, colors: MuiColorInputColors) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: colors.hex, //we use the destructured parameter
    });
  };
  return <MuiColorInput format="hex" value={value || ""} onChange={handleChange} disabled={!isEditMode} />;
}

export const tablesConfig: AdminDataTableConfig = {
  [CONSTANTS.USERS]: {
    title: "Users",
    description: "section to add users",
    buttonAddLabel: "Create User",
    columns: [
      {
        headerName: "Full Name",
        field: "full_name",
        type: "string",
        editable: true,
        width: 150,
      },
      {
        headerName: "Profile",
        field: "profile",
        type: "singleSelect",
        valueOptions: [
          { value: ROLES.ADMIN, label: "Admin" },
          { value: ROLES.SUPERUSER, label: "Super User" },
          { value: ROLES.SUPERVISOR, label: "Supervisor" },
          { value: ROLES.OPERATOR, label: "Operator" },
        ],
        editable: true,
        width: 100,
      }
    ],
  },
  [CONSTANTS.CODES]: {
    title: "Codes",
    description: "section to add codes",
    buttonAddLabel: "Add Code",
    columns: [
      // {
      //   headerName: "Is Active",
      //   field: "is_active",
      //   type: "boolean",
      //   editable: true,
      // }
    ],
  },
  [CONSTANTS.RESOURCES]: {
    title: "Resources",
    description: "section to add resources",
    buttonAddLabel: "Create User",
    columns: [
      {
        headerName: "Resource Id",
        field: "resource_id",
        type: "string",
        editable: false,
        renderCell: (params: any) => {
          return (
            <NonEditText value={params?.row?.resource_id} />
          )
        }
      },
      {
        headerName: "Resource Name",
        field: "resource",
        type: "string",
        editable: true,
        width: 120,
      },
      {
        headerName: "Resource Description",
        field: "resource_description",
        type: "string",
        editable: true,
        width: 200,
      },
      // {
      //   headerName: "Is Active",
      //   field: "is_active",
      //   type: "boolean",
      //   editable: true,
      //   width: 70,
      // },
    ],
  },
  [CONSTANTS.LOCATIONS]: {
    title: "Locations",
    description: "section to add locations",
    buttonAddLabel: "Create Location",
    columns: [
      // {
      //   headerName: "Location",
      //   field: "location",
      //   type: "string",
      //   editable: false,
      //   width: 120,
      // },
      {
        headerName: "Location Name",
        field: "location_name",
        type: "string",
        editable: true,
        width: 200,
      },
      {
        headerName: "Is Active",
        field: "is_active",
        type: "boolean",
        editable: true,
      },
    ],
  },
  [CONSTANTS.FORMATS]: {
    title: "",
    description: "Locations Formats",
    buttonAddLabel: undefined,
    columns: [
      {
        headerName: "Format Attribute",
        field: "format_attribute",
        type: "string",
        editable: true,
      },
      {
        headerName: "Format Regex",
        field: "format_regex",
        type: "string",
        editable: true,
      },
      {
        headerName: "Location",
        field: "location",
        type: "singleSelect",
        editable: true,
      },
      {
        headerName: "Is Active",
        field: "is_active",
        type: "boolean",
        editable: true,
      },
    ],
  },
  [CONSTANTS.CATEGORIES]: {
    title: "Categories",
    description:
      "The OEE Category field can not be changed once set as it is a key field.",
    buttonAddLabel: undefined,
    columns: [
      {
        headerName: "OEE Category",
        field: "oee_category",
        type: "string",
        editable: true,
        width: 200,
      },
      {
        headerName: "Category Color",
        field: "category_color",
        type: "string",
        editable: true,
        renderCell: (params) => <ColorPickerEditInputCell {...params} isEditMode={false} />,  // Not in edit mode
        renderEditCell: (params) => <ColorPickerEditInputCell {...params} isEditMode={true} />, // In edit mode
        width: 150,
      },
      {
        headerName: "Group",
        field: "category_group",
        type: 'singleSelect',
        editable: true,
        width: 150,
        valueOptions: [
          { value: GROUP.PLANNED, label: "Planned" },
          { value: GROUP.UNPLANNED, label: "Unplanned" },
          { value: GROUP.PRODUCTIVE, label: "Productive" },
          { value: GROUP.UNKNOWN, label: "Unknown" },
        ],
      },
      {
        headerName: "Is Active",
        field: "is_active",
        type: "boolean",
        editable: true,
      },
    ],
  },
  [CONSTANTS.TYPES]: {
    title: "Type Codes",
    description: undefined,
    buttonAddLabel: undefined,
    columns: [
      // {
      //   headerName: "Type Code",
      //   field: "type_code",
      //   type: "string",
      //   editable: false,
      // },
      {
        headerName: "Type Description",
        field: "type",
        type: "string",
        editable: true,
        width: 150,
      },
      {
        headerName: "Is Active",
        field: "is_active",
        type: "boolean",
        editable: true,
      },
    ],
  },
  [CONSTANTS.CELLS]: {
    title: "Cells",
    description:
      "The Cell field can not be changed once set as it is a key field.",
    buttonAddLabel: undefined,
    columns: [
      {
        headerName: "Is Active",
        field: "is_active",
        type: "boolean",
        editable: true,
      },
    ],
  },
};
