import { getData, putData, deleteData } from "./Api";
import { CONSTANTS } from "../configs/constants";

export const typesEndpoints = {
  get: async () => await getTypes(),
  put: async (data: any) => await putTypes(data),
  delete: async (data: string) => await deleteCells(data),
};

export async function getTypes() {
  const url = process.env.REACT_APP_BACKEND_URL + CONSTANTS.TYPES;
  return await getData(url);
}

export async function putTypes(data: any) {
  const url =
    process.env.REACT_APP_BACKEND_URL + CONSTANTS.TYPES + "/" + data.type_code;
  return await putData(url, data);
}

export async function deleteCells(data: any) {
  const url = process.env.REACT_APP_BACKEND_URL + CONSTANTS.TYPES + "/" + data.type_code;

  return await deleteData(url);
}
