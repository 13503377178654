import React from 'react';

interface DataItem {
    PK: string;
    SK: string;
    category_color: string;
    category_group: string | any;
    gsi1_PK: string;
    is_active: boolean;
    oee_category: string;
    good_bad: string;
}

interface DynamicTableProps {
    data: DataItem[];
}

const OEEWeekDisplayCaption: React.FC<DynamicTableProps> = ({ data }) => {
    // Extract unique columns
    const columns = [...new Set(data?.map(item => item?.category_group))];

    // Generate rows
    const rows = data.reduce<Record<string, DataItem[]>>((acc, item) => {
        const { category_group } = item;
        if (!acc[category_group]) {
            acc[category_group] = [];
        }
        acc[category_group].push(item);
        return acc;
    }, {});

    return (
        <table style={{ borderCollapse: 'collapse' }}>
            <thead>
                <tr>
                    {columns.map(col => (
                        <th key={col} style={{ padding: '10px', textAlign: 'center' }}>{col}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {columns.map(col => {
                        // Get items for the current column
                        const items = rows[col] || [];

                        // Track displayed categories and good_bad values
                        const displayedCategories = new Set<string>();
                        const displayedGoodBad = new Set<string>();

                        return (
                            <td key={col} style={{ verticalAlign: 'top', padding: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {items.reduce<JSX.Element[]>((acc, item, index) => {
                                        const { oee_category, good_bad, category_color } = item;

                                        // Track if this oee_category has already been displayed
                                        const categoryKey = `${oee_category}-${good_bad}`;
                                        if (!displayedCategories.has(categoryKey)) {
                                            displayedCategories.add(categoryKey);

                                            // Check if good_bad has already been displayed
                                            const showGoodBad = !displayedGoodBad.has(good_bad);
                                            if (showGoodBad) {
                                                displayedGoodBad.add(good_bad);
                                            }

                                            acc.push(
                                                <div
                                                    key={categoryKey}
                                                    style={{
                                                        position: 'relative',
                                                        backgroundColor: category_color,
                                                        borderRadius: '20px',
                                                        paddingRight: '20px',
                                                        paddingLeft: '20px',
                                                        textAlign: 'center',
                                                        height: '20px',
                                                        marginBottom: '10px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: 'black'
                                                    }}
                                                >
                                                    {good_bad === 'bad' ? (
                                                        <>
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '0',
                                                                    left: '25%',
                                                                    width: '1px',
                                                                    height: '100%',
                                                                    backgroundColor: 'black',
                                                                    zIndex: 0,
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '0',
                                                                    left: '50%',
                                                                    width: '1px',
                                                                    height: '100%',
                                                                    backgroundColor: 'black',
                                                                    zIndex: 0,
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '0',
                                                                    left: '75%',
                                                                    width: '1px',
                                                                    height: '100%',
                                                                    backgroundColor: 'black',
                                                                    zIndex: 0,
                                                                }}
                                                            />

                                                        </>
                                                    ) : null}

                                                    <span style={{ position: 'relative', zIndex: 1 }}>
                                                        {oee_category}
                                                        {showGoodBad && good_bad ? ` (${good_bad})` : null}
                                                    </span>
                                                </div>
                                            );
                                        }
                                        return acc;
                                    }, [])}
                                </div>
                            </td>
                        );
                    })}
                </tr>
            </tbody>
        </table>
    );
};

export default OEEWeekDisplayCaption;
