import { getData, putData, deleteData } from "./Api";
import { CONSTANTS } from "../configs/constants";
const moment = require('moment-timezone');

export interface Resource {
  location: string;
  resource: string;
  resource_description: string;
  resource_id: string;
  cell: string;
}

export const resourcesEndpoints = {
  get: async () => await getResources(),
  put: async (data: any) => await putResources(data),
  delete: async (data: string) => await deleteResources(data),
};

export async function getResources() {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    CONSTANTS.RESOURCES;
  return await getData(url);
}

export async function putResources(data: any) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    data.location +
    "/" +
    CONSTANTS.RESOURCES +
    "/" +
    data.resource_id;

  return await putData(url, data);
}

export async function deleteResources(data: any) {
  // transform user into api format
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    data.location +
    "/" +
    CONSTANTS.RESOURCES +
    "/" +
    data.resource_id;

  return await deleteData(url);
}

// -------------- ADDITIONAL ENDPOINTS ----------------
export async function getResourcesByLocation(location: string) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    location +
    "/" +
    CONSTANTS.RESOURCES;
  return await getData(url);
}

export async function getOEEValue(
  location: string,
  resource: string,
  calendarWeek: string
) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    location +
    "/" +
    CONSTANTS.RESOURCES +
    "/" +
    resource +
    "/oee?cal_week=" +
    calendarWeek;

  return await getData(url);
}

export async function getLatestEventByResource(
  location: string,
  resource: string
) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    location +
    "/" +
    CONSTANTS.RESOURCES +
    "/" +
    resource +
    "/events?top=1&sort=time_start";

  const data = await getData(url);

  if (data && data.length > 0) {
    return data[0];
  }

  if (data.status === 204) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let localDateTime = moment.tz(timezone).format('YYYY-MM-DD HH:mm:ss');
    let data = {
      time_start: localDateTime,
      time_finish: localDateTime
    }
    return data;
  }
  return null;
}

export async function getEventsByResource(location: string, resource: string) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    location +
    "/" +
    CONSTANTS.RESOURCES +
    "/" +
    resource +
    "/events?sort=time_finish";

  return await getData(url);
}
