import { useContext, useEffect, useState } from "react";
import { ResourceContext } from "src/store/ResourceContext";
import { getOEEValue } from "src/services/ResourcesEndpoints";

import "./OEEValueDisplay.scss";

function OEEValueDisplay() {
  const resourceContext = useContext(ResourceContext);

  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }

  const {
    selectedLocation,
    selectedResource,
    currentWeekNumber,
    stopButtonStatus,
  } = resourceContext;
  const [OEEValue, setOEEValue] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (selectedResource) {
        try {
          setOEEValue(
            (
              await getOEEValue(
                selectedLocation,
                selectedResource.resource_id,
                currentWeekNumber
              )
            ).score
          );
        } catch (error) {
          console.error("Failed to fetch OEE value:", error);
        }
      }
    }
    fetchData();
  }, [selectedLocation, selectedResource, currentWeekNumber, stopButtonStatus]);

  return selectedResource ? (
    <div
      className={
        "oee-value-display" +
        (OEEValue !== null && OEEValue > 72 ? "" : " red-bg")
      }
    >
      <div className="oee-value-display__title">OEE Current Week</div>
      <div className="oee-value-display__value">
        {OEEValue !== null ? `${OEEValue}%` : "Awaiting data"}
      </div>
      <div className="oee-value-display__label">Target OEE {">"}= 72%</div>
    </div>
  ) : (
    <div />
  );
}

export default OEEValueDisplay;
