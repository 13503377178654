import { useContext, useEffect, useState } from "react";
import "./ResourceSelection.scss";
import { ResourceContext } from "src/store/ResourceContext";
import {
  Resource,
  getResourcesByLocation,
} from "src/services/ResourcesEndpoints";

import "./ResourceSelection.scss";

function ResourceSelection() {
  const resourceSessionStorage = "selectedResource";

  const resourceContext = useContext(ResourceContext);

  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }

  const [resources, setResources] = useState<Resource[]>([]);
  const { selectedLocation, selectedResource, setSelectedResource } =
    resourceContext;

  const handleResourceChange = (event: { target: { value: string } }) => {
    const selectedResource = resources.find(
      (resource: Resource) => resource.resource === event.target.value
    );

    if (selectedResource) {
      setSelectedResource(selectedResource);
      sessionStorage.setItem(
        resourceSessionStorage,
        JSON.stringify(selectedResource)
      );
    } else {
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (selectedLocation) {
        // get all resources for the selected location
        const locationResources: Resource[] = await getResourcesByLocation(
          selectedLocation
        );
        setResources(locationResources);

        // check if there is a stored Resource
        const storedResource = sessionStorage.getItem(resourceSessionStorage);

        if (
          storedResource !== null &&
          locationResources.some(
            (resource) =>
              resource.resource_id === JSON.parse(storedResource).resource_id
          )
        ) {
          setSelectedResource(JSON.parse(storedResource));
        } else {
          setSelectedResource(locationResources[0]);
          sessionStorage.setItem(
            resourceSessionStorage,
            JSON.stringify(locationResources[0])
          );
        }
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  return resources ? (
    <select
      className="resource-selection"
      name="resourceSelection"
      id="resourceSelection"
      value={selectedResource?.resource}
      onChange={handleResourceChange}
    >
      {resources.map((resource: any) => (
        <option key={resource.resource} value={resource.resource}>
          {resource.resource}
        </option>
      ))}
    </select>
  ) : (
    <div> No resources associated with this location</div>
  );
}

export default ResourceSelection;
