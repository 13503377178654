import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  label: string;
}

export const LoadingIndicator = (props: Props) => {
  const { label } = props;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <CircularProgress />
      <div>{label}</div>
    </div>
  );
};
