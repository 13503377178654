import { appTitle, SELink } from "../../../../configs/appConfig";
import { ReactComponent as Logo } from "./../../../../assets/images/SE_Logo_Color_RGB.svg";
import "./Header.scss";

import LogoutIcon from "@mui/icons-material/Logout";

import { useMsal } from "@azure/msal-react";
import { useContext } from "react";
import { UserAccountContext } from "src/store/UserAccount";

export default function Header() {
  const { instance } = useMsal();
  const user = useContext(UserAccountContext);

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <header className="se-header">
      <div className="se-header__title">
        <a href={SELink} className="se-header__logo">
          <Logo />
        </a>

        <h1>{appTitle}</h1>
      </div>
      <div className="se-header__user">
        <div className="se-header__user-info">
          <p>
            <b>{user.full_name}</b>
          </p>
          <p>{user.profile}</p>
        </div>

        <LogoutIcon
          className="se-header__logout"
          onClick={() => handleLogout()}
        />
      </div>
    </header>
  );
}
