import { useEffect, useState, ComponentType, useContext } from "react";
import dayjs, { Dayjs } from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import updateLocale from "dayjs/plugin/updateLocale";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";

import "./OEEWeekPicker.scss";
import { ResourceContext } from "src/store/ResourceContext";

dayjs.extend(isBetweenPlugin);
dayjs.extend(updateLocale);
dayjs.extend(weekOfYear);

dayjs.updateLocale("en", {
  weekStart: 1,
});

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(({ isSelected, isHovered, day }) => ({
  borderRadius: 0,

  ...(isSelected && {
    backgroundColor: "#641e8c",
    color: "#f2f2f2",
    "&:hover, &:focus": {
      backgroundColor: "#641e8c",
    },
  }),
  ...(isHovered && {
    backgroundColor: "#b96cff",
    "&:hover, &:focus": {
      backgroundColor: "#8a00e5",
    },
  }),
  ...(day.day() === 1 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(day.day() === 0 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, "week");
};

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null;
    hoveredDay?: Dayjs | null;
  }
) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

export default function OEEWeekPicker() {

  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null);
  const [value, setValue] = useState<Dayjs | null>(dayjs());
  const [toogleCalendar, setToogleCalendar] = useState(false);

  const resourceContext = useContext(ResourceContext);

  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }
  const { setSelectedWeekNumber, setSelectedWeek, selectedWeek } = resourceContext;

  useEffect(() => {
    async function updateStartEndWeek() {
      if (value) {
        const startDate = value.startOf("week").format("DD/MMM/YYYY");
        const endDate = value.endOf("week").format("DD/MMM/YYYY");
        setSelectedWeek({ startDate: startDate, endDate: endDate });

        const weekNumber =
          value.year() +
          "-" +
          (value.week() < 10 ? "0" + value.week() : value.week());

        const startDayOfWeek = value.startOf("week").toDate();

        setSelectedWeekNumber({ weekNumber, startDayOfWeek });
      } else {
        setSelectedWeek({ startDate: '', endDate: '' });
      }
    }
    updateStartEndWeek();
  }, [setSelectedWeekNumber, value, setSelectedWeek]);

  return (
    <>
      <div className="oee-week-picker__button">
        <p>Displaying results for week: </p>
        <button onClick={() => setToogleCalendar(!toogleCalendar)}>
          {selectedWeek?.startDate} - {selectedWeek?.endDate}
        </button>
      </div>

      {toogleCalendar && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              setToogleCalendar(!toogleCalendar);
            }}
            showDaysOutsideCurrentMonth
            displayWeekNumber
            slots={{ day: Day }}
            slotProps={{
              day: (ownerState) =>
              ({
                selectedDay: value,
                hoveredDay,
                onPointerEnter: () => setHoveredDay(ownerState.day),
                onPointerLeave: () => setHoveredDay(null),
              } as any),
            }}
          />
        </LocalizationProvider>
      )}
    </>
  );
}
