// Function to get the next prefix
export function getNextPrefix(prefixes: string[]): string {
    // Filter prefixes that start with "R"
    const rPrefixes = prefixes.filter(prefix => prefix.startsWith("R"));

    // Extract the numeric part and convert to numbers
    const numbers = rPrefixes.map(prefix => parseInt(prefix.substring(1)));

    // Find the maximum number
    const maxNumber = Math.max(...numbers);

    // Generate the next prefix
    const nextNumber = maxNumber + 1;
    const nextPrefix = `R${nextNumber.toString().padStart(3, '0')}`;

    return nextPrefix;
}
