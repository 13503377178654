import { useContext, useEffect, useState } from "react";
import { ResourceContext } from "../../store/ResourceContext";

import "./RecordsView.scss";
import SEDataGrid from "./../../features/admin-data-tables/SEDataGrid";
import { AdminDataTableColumn } from "@features/admin-data-tables/adminDataTablesConfig";
import { getEventsByLocation } from "src/services/EventsEndpoints";
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc'

dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

const columns: AdminDataTableColumn[] = [
  {
    headerName: "Asset#",
    field: "resource",
    type: "string",
    editable: false,
    width: 110,
  },
  {
    headerName: "Asset Type",
    field: "resource_description",
    type: "string",
    editable: false,
    width: 150,
  },
  {
    headerName: "Resource ID",
    field: "resource_id",
    type: "string",
    editable: false,
    width: 150,
  },
  {
    headerName: "Modified At",
    field: "modified_at",
    type: "string",
    editable: false,
    width: 150,
  },
  {
    headerName: "Modified By",
    field: "modified_by",
    type: "string",
    editable: false,
    width: 150,
  },
  {
    headerName: "WO#",
    field: "wo_number",
    type: "string",
    editable: true,
  },
  {
    headerName: "Good/Bad",
    field: "good_bad",
    type: "singleSelect",
    editable: true,
    valueOptions: [
      { value: "good", label: "Good" },
      { value: "bad", label: "Bad" },
    ],
  },
  {
    headerName: "Duration hrs",
    field: "duration",
    type: "string",
    editable: false,
  },
  {
    headerName: "Comment",
    field: "event_description",
    type: "string",
    editable: true,
  },
];

export default function RecordsView() {
  const resourceContext = useContext(ResourceContext);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }

  const {
    selectedLocation,
    startWeekDate,
    endWeekDate,
  } = resourceContext;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (startWeekDate && endWeekDate && selectedLocation) {
        const startWeekFormatted = startWeekDate.format('YYYY-WW');
        const endWeekFormatted = endWeekDate.format('YYYY-WW');
        getEventsByLocation(selectedLocation, startWeekFormatted, endWeekFormatted).then(
          (response) => {
            setLoading(false);
            if (response) {
              setRows(response);
            }
          }
        );
      }

    }
    fetchData();
  }, [
    endWeekDate,
    selectedLocation,
    startWeekDate,
  ]);


  return (
    <>
      <h2>
        Records from {startWeekDate?.format('YYYY/MM/DD')} to {endWeekDate?.format('YYYY/MM/DD')}
      </h2>
      <SEDataGrid
        isRecords={true}
        loading={loading}
        columns={columns}
        rows={rows}
        section="events"
      />
    </>
  );
}
