
import SEDataGrid from "./../../features/admin-data-tables/SEDataGrid";
import { AdminDataTableColumn } from "@features/admin-data-tables/adminDataTablesConfig";
import { Box, Modal } from "@mui/material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const columns: AdminDataTableColumn[] = [
    {
        headerName: "Audit Difference",
        field: "audit_diff",
        type: "string",
        editable: false,
        width: 200,
    },
    {
        headerName: "New Values",
        field: "audit_new",
        type: "string",
        editable: false,
        width: 200,
    },
    {
        headerName: "Old Values",
        field: "audit_old",
        type: "string",
        editable: false,
        width: 200,
    },
    {
        headerName: "Modified at",
        field: "modified_at",
        type: "string",
        editable: false,
        width: 200,
    },
    {
        headerName: "Modified By",
        field: "modified_by",
        type: "string",
        editable: false,
        width: 110,
    },
];

export default function ModalAudit({
    open,
    handleClose,
    rows,
    loading,
}: any) {

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" >
                <Box sx={style}>
                    <SEDataGrid
                        isRecords={true}
                        loading={loading}
                        columns={columns}
                        rows={rows}
                        section="modalAudit"
                    />
                </Box>
            </Modal>
        </>
    );
}
