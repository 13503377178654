// Constants defined outside since they don't change
import { TimeSlot } from "./OEEWeekDisplay.types";

const numTimeSlotsPerHour = 6;
const numHoursDisplayed = 19; // from 5am to 23pm
const numSlotsPerDay = numTimeSlotsPerHour * numHoursDisplayed;
export const hours: string[] = Array.from({ length: 19 }, (_, i) => `${i + 5}:00`);


// Introduced helper function
function createTimeSlot(i: number, startDate: Date): TimeSlot {
    const dayOfWeek = Math.floor(i / numSlotsPerDay);
    const startTime = new Date(startDate);
    startTime.setDate(startDate.getDate() + dayOfWeek);
    startTime.setHours(
        5 + Math.floor(i / numTimeSlotsPerHour),
        (i % numTimeSlotsPerHour) * 10,
        0,
        0
    );
    const endTime = new Date(startTime);
    endTime.setMinutes(endTime.getMinutes() + 10);
    return { startTime, endTime, event: null }; // use default value
}

// Refactored generateTimeSlots function
export function generateTimeSlots(startDate: Date): TimeSlot[] {
    return Array.from(
        { length: numSlotsPerDay },
        (_, i) => createTimeSlot(i, startDate)
    );
}

