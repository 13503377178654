export const CONSTANTS = {
  USERS: "users",
  RESOURCES: "resources",
  CODES: "codes",
  CATEGORIES: "categories",
  CELLS: "cells",
  LOCATIONS: "locations",
  FORMATS: "formats",
  EVENTS: "events",
  TYPES: "types",
  AUDITS: "audits",
};

export default CONSTANTS;

export const ROLES = {
  ADMIN: "Admin",
  SUPERUSER: "Superuser",
  SUPERVISOR: "Supervisor",
  OPERATOR: "Operator",
};

export const GROUP = {
  PLANNED: 'Planned',
  UNPLANNED: 'Unplanned',
  PRODUCTIVE: 'Productive',
  UNKNOWN: 'Unknown',
}

export const EVENT_ACTIONS = {
  START: "Start",
  STOP: "Stop",
  DELETE: "Delete",
  EDIT: "Edit",
};
