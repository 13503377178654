import { useMsal } from "@azure/msal-react";

import { loginRequest } from "../../configs/authConfig";

export default function SignInButton() {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <button onClick={() => handleLogin()} key="loginRedirect">
      Login
    </button>
  );
}
