import { getData, putData, deleteData } from "./Api";
import { CONSTANTS } from "../configs/constants";

export const locationEndpoints = {
  get: async (user: any) => await getLocations(user),
  put: async (data: any) => await putLocations(data),
  delete: async (gid: string) => await deleteLocations(gid),
};

export async function getLocation(location: string) {
  const url =
    process.env.REACT_APP_BACKEND_URL + CONSTANTS.LOCATIONS + "/" + location;
  return await getData(url);
}

export async function getLocations(user: any) {
  const url = process.env.REACT_APP_BACKEND_URL + CONSTANTS.LOCATIONS;
  // get only locations of the user
  return await getData(url);
}

export async function putLocations(data: any) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    data.location;

  return await putData(url, data);
}

export async function deleteLocations(data: any) {
  // transform user into api format
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    data.location;

  return await deleteData(url);
}
