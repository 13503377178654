import "./ActionButton.scss";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";

interface ButtonProps {
  text: string;
  isActive: boolean;
  onClick: () => void;
}

function Button({ text, isActive, onClick }: ButtonProps) {
  return (
    <button onClick={onClick} className="action-button" disabled={!isActive}>
      <p>{text}</p>

      {text === "Start" ? <PlayArrowIcon /> : <StopIcon />}
    </button>
  );
}

export default Button;
