import { useContext, useEffect, useState } from "react";

import { ResourceContext } from "src/store/ResourceContext";
import { Event } from "src/services/EventsEndpoints";
import { getCategoriesColor } from "src/services/CategoriesEndpoints";

import { getLocalFormattedDate, getLocalFormattedforDifference } from "src/utils/Utils";
import "./CurrentCodeDisplay.scss";

type CurrentCodeDisplayProps = {
  event: Event;
};

function CurrentCodeDisplay({ event }: CurrentCodeDisplayProps) {
  const resourceContext = useContext(ResourceContext);
  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }
  const [borderColor, setBorderColor] = useState<string>();
  //@ts-ignore
  const [currentTime, setCurrentTime] = useState(new Date(new Date().toISOString()));

  const [difference, setDifference] = useState('')

  const calcDuration = (dateString: string, currTime: Date) => {

    // Parse the input date string
    const updatedData = getLocalFormattedforDifference(dateString)

    const targetDate = new Date(updatedData);

    // Calculate the difference in milliseconds
    const difference = currTime.getTime() - targetDate.getTime();

    // Convert the difference into days, hours, minutes, and seconds
    let remaining = Math.abs(difference);
    const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
    remaining -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(remaining / (1000 * 60 * 60));
    remaining -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(remaining / (1000 * 60));

    // Dynamically build the result string based on the values
    let result = difference < 0 ? "-" : "";
    result += days > 0 ? `${Math.abs(days)} days, ` : "";
    result += hours > 0 ? `${Math.abs(hours)} hours, ` : "";
    result += minutes > 0 ? `${Math.abs(minutes)} minutes, ` : "";

    // Remove any trailing comma and space
    result = result.replace(/, $/, "");

    // Handle case where difference is 0
    if (!result) {
      result = "0 seconds";
    }

    // Format the result
    return result;

  }

  useEffect(() => {
    async function getBorderColor() {
      const color = await getCategoriesColor([event.oee_category]);
      setBorderColor(color[0].color);
    }

    getBorderColor();

    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 600000); // 10 minutes in milliseconds

    if (event.time_start) {
      setDifference(calcDuration(event.time_start, currentTime));
    }

    return () => clearInterval(interval);

  }, [event, currentTime]);

  return (
    <>
      <div className="current-code-display" style={{ borderColor }}>
        <div className="current-code-display__header">
          <label>Current Code</label>
          <div className="current-code-display__actions">
            {/* <EditIcon onClick={() => handleToogleDialog(EVENT_ACTIONS.EDIT)} />
            <DeleteIcon
              onClick={() => handleToogleDialog(EVENT_ACTIONS.DELETE)}
            /> */}
          </div>
        </div>
        <div className="current-code-display__code">
          <div className="current-code-display__value">
            <label>OEE Code Type</label>
            <span>{event.oee_description}</span>
          </div>
          <div className="current-code-display__value">
            <label>OEE Value</label>
            <span>
              {event.oee_code}{" "}
              {event.oee_description &&
                event.oee_description.includes("Works Order")
                ? event.wo_number
                : ""}
            </span>
          </div>
          <div className="current-code-display__value">
            <label>Duration</label>
            <span>
              {event.time_start &&
                difference}
            </span>
          </div>
          <div className="current-code-display__value">
            <label>Start Date and Time</label>
            <span>{getLocalFormattedDate(event.time_start)}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default CurrentCodeDisplay;
