import SignInButton from "./../components/login/SignInButton";

export default function Login() {
  return (
    <div>
      <h1>LOGIN</h1>
      <p>Sorry, you are not authenticated</p>
      <SignInButton />
    </div>
  );
}
