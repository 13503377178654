import { CONSTANTS } from "../configs/constants";

// endpoints list
import { userEndpoints } from "./UserEndpoints";
import { resourcesEndpoints } from "./ResourcesEndpoints";
import { codesEndpoints } from "./CodesEndpoints";
import { categoriesEndpoints } from "./CategoriesEndpoints";
import { cellsEndpoints } from "./CellsEndpoints";
import { locationEndpoints } from "./LocationsEndpoints";
import { formatsEndpoints } from "./FormatsEndpoints";
import { typesEndpoints } from "./TypesEndpoints";
import { eventsEndpoints } from "./EventsEndpoints";

export const endpoints = {
  [CONSTANTS.USERS]: userEndpoints,
  [CONSTANTS.RESOURCES]: resourcesEndpoints,
  [CONSTANTS.CODES]: codesEndpoints,
  [CONSTANTS.CATEGORIES]: categoriesEndpoints,
  [CONSTANTS.CELLS]: cellsEndpoints,
  [CONSTANTS.LOCATIONS]: locationEndpoints,
  [CONSTANTS.FORMATS]: formatsEndpoints,
  [CONSTANTS.TYPES]: typesEndpoints,
  [CONSTANTS.EVENTS]: eventsEndpoints,
};
