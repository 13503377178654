import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// import { ChevronDownIcon } from "@heroicons/react/outline";

import "./../SideNavbar.scss";

const resolveLinkPath = (childTo: any, parentTo: any) =>
  `${parentTo}/${childTo}`;

const NavItemHeader = (props: { item: any }) => {
  const { item } = props;
  const { label, children } = item;
  // const location = useLocation();

  const [expanded, setExpand] = useState(
    // location.pathname.includes(headerToPath)
    true
  );

  const onExpandChange = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setExpand((expanded) => !expanded);
  };

  return (
    <>
      <button className="nav-item-header__item" onClick={onExpandChange}>
        <span>{label}</span>
      </button>

      {expanded && (
        <div className="nav-item-header__children">
          {children.map(
            (
              item: { label: any; url?: any; icon?: any; children?: any },
              index: any
            ) => {
              const key = `${item.label}-${index}`;

              const { label, children } = item;

              if (children) {
                return (
                  <div key={key}>
                    <NavItemHeader
                      item={{
                        ...item,
                        to: resolveLinkPath(item.url, props.item.url),
                      }}
                    />
                  </div>
                );
              }

              return (
                <NavLink
                  key={key}
                  to={resolveLinkPath(item.url, props.item.url)}
                  className="nav-item-header__item nav-item-header__item--children"
                // activeClassName="active-nav-item"
                >
                  <span>{label}</span>
                </NavLink>
              );
            }
          )}
        </div>
      )}
    </>
  );
};

export default NavItemHeader;
