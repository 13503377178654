import { useContext } from "react";

import { ResourceProvider } from "../store/ResourceContext";
import { UserAccountContext } from "src/store/UserAccount";

import LocationSelection from "../components/location-selection/LocationSelection";
import ResourceSelection from "../components/resource-selection/ResourceSelection";
import OEEValueDisplay from "src/components/oee-value-display/OEEValueDisplay";
import CodeControl from "../components/code-control/CodeControl";
import CodeDialog from "../components/code-dialog/CodeDialog";
import CurrentWeekDisplay from "../components/current-week-display/CurrentWeekDisplay";
import OEEWeekDisplay from "../components/oee-week-display/OEEWeekDisplay";
import OEEWeekPicker from "../components/oee-week-picker/OEEWeekPicker";

export default function OEEPanel() {
  const user = useContext(UserAccountContext);
  const userLocations = user.user_locations;

  return userLocations ? (
    <ResourceProvider>
      <div className="layer">
        <LocationSelection />
      </div>
      <div className="layer oee-panel__controls">
        <ResourceSelection />
        <CurrentWeekDisplay />
        <OEEValueDisplay />
      </div>
      <div className="layer">
        <div>
          <CodeControl />
        </div>
      </div>
      <div className="layer">
        <div className="oee-panel__resource-status">
          <h3>Resource Status</h3>
          <div>
            <OEEWeekPicker />
          </div>
        </div>

        <OEEWeekDisplay />
      </div>
      <CodeDialog />
    </ResourceProvider>
  ) : (
    <div className="layer">
      No user locations associated with this user. <br />
      Request an Admin to add locations for your user by accessing Settings{" "}
      {">"} Users {">"} Edit User, and add a location value under the "User
      Location" column.
    </div>
  );
}
