import { getData } from "./Api";
import { CONSTANTS } from "../configs/constants";

export const formatsEndpoints = {
  get: async () => await getFormats(),
  put: async (data: any) => await putFormats(data),
  delete: async (gid: string) => await deleteFormats(gid),
};

export async function getFormats() {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/MAN BM/" +
    CONSTANTS.FORMATS;
  return await getData(url);
}

export async function putFormats(data: any) {
  // const url =
  //   process.env.REACT_APP_BACKEND_URL +
  //   CONSTANTS.USERS +
  //   "/" +
  //   data.gid;

  //return await putData(url, data);
  return data;
}

export async function deleteFormats(gid: string) {
  // const url =
  //   process.env.REACT_APP_BACKEND_URL +
  //   CONSTANTS.USERS +
  //   "/" +
  //   gid;
  //return await deleteData(url);
  return gid;
}
