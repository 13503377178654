import { useParams } from "react-router";
import SEDataGrid from "./SEDataGrid";
import { useEffect, useState, useContext } from "react";

import CONSTANTS from "./../../configs/constants";

import { useNavigate } from "react-router-dom";

import { endpoints } from "../../services/Endpoints";

import {
  AdminDataTableSection,
  AdminDataTableContent,
  tablesConfig,
  AdminDataTableColumn,
} from "./adminDataTablesConfig";
import { UserAccountContext } from "src/store/UserAccount";
import "./SEDataGrid.scss";

export default function AdminDataTables() {
  const user = useContext(UserAccountContext);

  const { section } = useParams<{ section: AdminDataTableSection }>();
  const [rows, setRows] = useState([]);
  const [config, setConfig] = useState<AdminDataTableContent>();
  const [columns, setColumns] = useState<AdminDataTableColumn[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setRows([]);
      setLoading(true);

      if (section !== undefined) {
        const config = tablesConfig[section];

        if (config) {
          // call data dynamically
          const dynamicGet = endpoints[section]["get"];

          dynamicGet(user).then((response) => {
            setLoading(false);
            if (response) {
              setRows(response);
            }
          });

          setColumns(config.columns);

          modifyTablesConfig(config.columns, user).then((dynamicColumns) => {
            setColumns(dynamicColumns);
          });

          setConfig(config);
        } else {
          console.error(`Invalid section: ${section}`);
          navigate("/404");
        }
      }
    }
    fetchData();
  }, [navigate, section, user]);

  return (
    <div className="layer admin-data-table">
      <h2>{config?.title}</h2>
      <p>{config?.description}</p>

      <SEDataGrid
        loading={loading}
        columns={columns}
        rows={rows}
        section={section}
      />
    </div>
  );
}

async function modifyTablesConfig(
  tableConfig: AdminDataTableColumn[],
  user: any
) {
  // Iterate over the columns
  for (let column of tableConfig) {
    // Check if this has the "location" column

    if (column.type === "singleSelect") {
      if (column.field === "location") {
        // Call the getLocations function
        const dropdownLocations = await endpoints[CONSTANTS.LOCATIONS]["get"](
          user
        );

        if (dropdownLocations) {
          // Map locations to the required format
          column.valueOptions = dropdownLocations?.map((item: any) => ({
            value: item.location,
            label: item.location,
          }));
        }
      }

      if (column.field === "oee_category") {
        // Call the categories function
        const dropdownCategories = await endpoints[CONSTANTS.CATEGORIES]["get"](
          ""
        );

        if (dropdownCategories) {
          // Map locations to the required format
          column.valueOptions = dropdownCategories?.map((item: any) => ({
            value: item.oee_category,
            label: item.oee_category,
          }));
        }
      }

      if (column.field === "cell") {
        // Call the categories function
        const dropdownCells = await endpoints[CONSTANTS.CELLS]["get"]("");

        if (dropdownCells) {
          // Map locations to the required format
          column.valueOptions = dropdownCells?.map((item: any) => ({
            value: item.cell,
            label: item.cell,
          }));
        }
      }

      if (column.field === "type_code") {
        // Call the categories function
        const dropdownTypeCodes = await endpoints[CONSTANTS.TYPES]["get"]("");

        if (dropdownTypeCodes) {
          // Map locations to the required format
          column.valueOptions = dropdownTypeCodes?.map((item: any) => ({
            value: item.type_code,
            label: item.type_code,
          }));
        }
      }
    }
  }

  return tableConfig;
}
