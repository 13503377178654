import { Box, TextField } from "@mui/material";

interface iTextField {
    value: string;
    onChange: () => void;
}

function TextInput({ value, onChange }: iTextField) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                '& > :not(style)': { m: 1, },
            }}
        >
            <TextField
                value={value}
                onChange={onChange}
            />
        </Box>
    );
}

export default TextInput;
