import { sideNavbar } from "../../../../configs/appConfig";
import NavItem from "./navItem/NavItem";

import "./SideNavbar.scss";

export default function SideNavbar() {
  return (
    <>
      <aside className="side-navbar">
        <nav>
          {sideNavbar.map((item, index) => {
            return <NavItem key={`${item.label}-${index}`} item={item} />;
          })}
        </nav>
      </aside>
    </>
  );
}
