import { msalInstance } from "../index";
import { apiConfig } from "../configs/authConfig";


async function request(url: RequestInfo | URL, options: RequestInit) {
  const token = await getSilentToken();
  const headers = {
    ...options.headers,
    Authorization: `${token}`,
  };
  const response = await fetch(url, { ...options, headers });
  if (response.status === 204) {
    return response;
  }
  let data = null;
  try {
    data = await response.json();
    if (response.status === 200) {
      return data;
    } else {
      return response
    }
  } catch (error) {
    console.error("error request", error);
    return null;
  }
}

export async function getSilentToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: apiConfig.scopes,
  });

  return response.accessToken;
}

export async function getData(url: RequestInfo | URL) {
  return request(url, { method: "GET" });
}

export async function postData(url: RequestInfo | URL, data: any) {
  return request(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export async function putData(url: RequestInfo | URL, data: any) {
  return request(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then(response => {
    return response;
  })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('error PutData ', error)
    })
}

export async function deleteData(url: RequestInfo | URL) {
  return request(url, { method: "DELETE" });
}
