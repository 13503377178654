import { createContext, useState, ReactNode, useEffect } from "react";
import { Resource } from "../services/ResourcesEndpoints";
import { Event } from "src/services/EventsEndpoints";
import { Category, getCategories } from "../services/CategoriesEndpoints";
import dayjs, { Dayjs } from "dayjs";

interface ResourceContextType {
  selectedResource: Resource | null;
  setSelectedResource: React.Dispatch<React.SetStateAction<Resource | null>>;

  selectedLocation: string;
  setSelectedLocation: React.Dispatch<React.SetStateAction<string>>;

  currentWeekNumber: string;
  setCurrentWeekNumber: React.Dispatch<React.SetStateAction<string>>;

  currentWeekStartDate: string | undefined;
  setCurrentWeekStartDate: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;

  selectedWeek: { startDate: string; endDate: string };
  setSelectedWeek: React.Dispatch<
    React.SetStateAction<{ startDate: string; endDate: string }>
  >;

  selectedWeekNumber: { weekNumber: string; startDayOfWeek: Date };
  setSelectedWeekNumber: React.Dispatch<
    React.SetStateAction<{ weekNumber: string; startDayOfWeek: Date }>
  >;

  startWeekDate: Dayjs | null;
  setStartWeekDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;

  endWeekDate: Dayjs | null;
  setEndWeekDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;

  startButtonStatus: boolean;
  setStartButtonStatus: React.Dispatch<React.SetStateAction<boolean>>;

  stopButtonStatus: boolean;
  setStopButtonStatus: React.Dispatch<React.SetStateAction<boolean>>;

  toogleDialog: boolean;
  setToogleDialog: React.Dispatch<React.SetStateAction<boolean>>;

  eventDialog: Event | null;
  setEventDialog: React.Dispatch<React.SetStateAction<Event | null>>;

  categories: Category[];
}

export const ResourceContext = createContext<ResourceContextType | null>(null);

interface ResourceProviderProps {
  children: ReactNode;
}

export function ResourceProvider({ children }: ResourceProviderProps) {

  const [selectedResource, setSelectedResource] = useState<Resource | null>(
    null
  );

  const [categories, setCategories] = useState<any[]>([]);

  const [selectedLocation, setSelectedLocation] = useState("");

  const [currentWeekNumber, setCurrentWeekNumber] = useState<string>("");

  const [selectedWeek, setSelectedWeek] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });

  const [selectedWeekNumber, setSelectedWeekNumber] = useState<{
    weekNumber: string;
    startDayOfWeek: Date;
  }>({
    weekNumber: "",
    startDayOfWeek: new Date(new Date().toISOString()),
  });

  const [currentWeekStartDate, setCurrentWeekStartDate] = useState<
    string | undefined
  >();

  const [startWeekDate, setStartWeekDate] = useState<Dayjs | null>(dayjs().subtract(30, 'day'));
  const [endWeekDate, setEndWeekDate] = useState<Dayjs | null>(dayjs());

  const [startButtonStatus, setStartButtonStatus] = useState(false);
  const [stopButtonStatus, setStopButtonStatus] = useState(false);

  const [toogleDialog, setToogleDialog] = useState(false);
  const [eventDialog, setEventDialog] = useState<Event | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesResponse = await getCategories(true);
      setCategories(categoriesResponse);
    }
    fetchCategories();
  }, []);

  return (
    <ResourceContext.Provider
      value={{
        selectedResource,
        setSelectedResource,

        selectedLocation,
        setSelectedLocation,

        currentWeekNumber,
        setCurrentWeekNumber,

        selectedWeek,
        setSelectedWeek,

        selectedWeekNumber,
        setSelectedWeekNumber,

        currentWeekStartDate,
        setCurrentWeekStartDate,

        startWeekDate,
        setStartWeekDate,

        endWeekDate,
        setEndWeekDate,

        startButtonStatus,
        setStartButtonStatus,

        stopButtonStatus,
        setStopButtonStatus,

        toogleDialog,
        setToogleDialog,

        eventDialog,
        setEventDialog,

        categories
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
}
