import { RouterProvider, createBrowserRouter } from "react-router-dom";
// MSAL imports
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { IPublicClientApplication } from "@azure/msal-browser";

import Login from "./pages/Login";
import OEEPanel from "./pages/OEEPanel";
import Settings from "./pages/Settings";
import ErrorPage from "./pages/ErrorPage";

import Header from "./components/layout/ui/header/Header";
import AdminDataTables from "./features/admin-data-tables/AdminDataTables";

import UserAccountProvider from "./store/UserAccount";
import Layout from "./pages/Layout";
import Records from "./pages/Records";
import Footer from "./components/layout/ui/footer/Footer";

import { version, brand } from "./configs/appConfig";
import About from "./pages/About";

type AppProps = {
  msalInstance: IPublicClientApplication;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <OEEPanel />,
      },
      {
        path: "records/",
        element: <Records />,
      },
      {
        path: "settings/",
        element: <Settings />,
      },
      {
        path: "about/",
        element: <About />,
      },
      {
        path: "settings/:section",
        element: <AdminDataTables />,
        errorElement: <ErrorPage />,
      },
      {
        path: "login/",
        element: <Login />,
      },
    ],
  },
]);

function App({ msalInstance }: AppProps) {
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <AuthenticatedTemplate>
          <UserAccountProvider>
            <div>
              <Header />
              <RouterProvider router={router} />
            </div>
          </UserAccountProvider>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>

        <Footer version={version} brand={brand} />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;