import { msalInstance } from "../index";
import { createContext, useEffect, useState } from "react";

import { getUser } from "../services/UserEndpoints";
import { addAppstoreUserAccess } from "../services/GenericEndpoints";

import { LoadingIndicator } from "src/components/layout/ui/loading-indicator/LoadingIndicator";

export interface User {
  gid: string;
  full_name: string;
  profile: string;
  location: string;
  is_active: boolean;
  user_locations: string[];
}

export const UserAccountContext = createContext<User>({} as User);

function UserAccountProvider(props: { children: React.ReactNode }) {
  const [user, setUser] = useState<User>({} as User);
  const [isLoading, setIsLoading] = useState(true);

  const account = msalInstance.getActiveAccount();
  const gid = account?.idTokenClaims?.gid as string;

  useEffect(() => {
    async function fetchData() {
      const data = await getUser(gid);
      setUser(data);
      setIsLoading(false);

      // write usage on appstore
      addAppstoreUserAccess(gid);
    }
    fetchData();
  }, [gid]);

  return isLoading ? (
    <LoadingIndicator label="Loading user information" />
  ) : (
    <UserAccountContext.Provider value={user}>
      {props.children}
    </UserAccountContext.Provider>
  );
}

export default UserAccountProvider;
