import { useContext, useEffect, useState } from "react";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import { ResourceContext } from "src/store/ResourceContext";
import { Event, putEvent } from "src/services/EventsEndpoints";

import { EVENT_ACTIONS } from "src/configs/constants";

import { formatDateToISO, getLocalFormattedDate } from "src/utils/Utils";

import {
  Code,
  getCodesforLocationAndCelltype,
} from "src/services/CodesEndpoints";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import "./CodeDialog.scss";
import Button from "../layout/ui/button/Button";
import DialogContent from "@mui/material/DialogContent";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function CodeDialog() {
  // all codes for this resource (location and type)
  const [codeList, setCodeList] = useState<Code[]>([]);
  const [event, setEvent] = useState<Event>({
    time_finish: "",
    resource_description: "",
    oee_category: "",
    resource_id: "",
    oee_code: "",
    location: "",
    oee_description: "",
    wo_category: "",
    time_start: "",
    wo_number: "",
    is_active: "True",
    resource: "",
    good_bad: "",
    event_description: "",
  });

  const [selectedOECodeTypeDescription, setSelectedOEECodeTypeDescription] =
    useState<string>();
  const [selectedOEECode, setSelectedOEECode] = useState<Code>();
  const [WONumber, setWONumber] = useState<string | null>("");
  const [comment, setComment] = useState<string | null>();
  const [WOGoodBad, setWOGoodBad] = useState<string>("good");

  const [codeTypes, setCodeTypes] = useState<string[]>([]);
  const [filteredCodeList, setFilteredCodeList] = useState<Code[]>([]);
  const [title, setTitle] = useState<string>("1");

  const [endDateTime, setEndDateTime] = useState<Date | null>(null);

  const [showSummary, setShowSummary] = useState(false);

  // Button status
  const [startCodeButtonStatus, setStartCodeButtonStatus] = useState(false);
  const [discardButtonStatus, setDiscardButtonStatus] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [stopCodeButtonStatus, setStopCodeButtonStatus] = useState(false);

  const resourceContext = useContext(ResourceContext);

  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }

  const {
    selectedResource,
    toogleDialog,
    setToogleDialog,
    eventDialog,
    setEventDialog,
  } = resourceContext;

  const minDateTime = dayjs(eventDialog?.time_start).utc()

  // Get the current date-time in local timezone
  const now = dayjs();

  // Calculate the GMT offset in minutes
  const gmtOffsetMinutes = now.utcOffset();


  const handleClose = () => {
    setToogleDialog(false);
    // clear dialog event after closing
    resetDialogSelection();
    setShowSummary(false);
    setWONumber("");
    setComment("");
  };

  function getDuration(timeStart: any, timeFinish: any) {
    const eventStartTime = new Date(timeStart);
    const eventEndTime = new Date(timeFinish);

    const durationInMs = Math.abs(
      eventEndTime.getTime() - eventStartTime.getTime()
    );

    // Convert duration from milliseconds to minutes
    const durationInMinutes = durationInMs / 1000 / 60;

    // Calculate the number of whole hours and minutes in the duration
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);

    return `${hours}:${minutes}`;
  }

  async function resetDialogSelection() {
    setEventDialog(null);
    setSelectedOEECode(undefined);
    setSelectedOEECodeTypeDescription("");
    setStartCodeButtonStatus(false);
    setDiscardButtonStatus(false);
    setSaveButtonStatus(false);
    setStopCodeButtonStatus(false);
    setComment(null);
    setWOGoodBad("good");
    setEvent({
      time_finish: "",
      resource_description: "",
      oee_category: "",
      resource_id: "",
      oee_code: "",
      location: "",
      oee_description: "",
      wo_category: "",
      time_start: "",
      wo_number: "",
      is_active: "True",
      resource: "",
      good_bad: "",
      event_description: "",
    });
  }

  function checkStartConfirmStatus() {
    return !((selectedOECodeTypeDescription === "Works Order (WO)" &&
      selectedOEECode &&
      WONumber !== "") ||
      (selectedOECodeTypeDescription !== "Works Order (WO)" && selectedOEECode));

  }

  function Summary() {
    return (
      <div>
        <p>
          <b>Asset#:</b> {event.resource}
        </p>
        <p>
          <b>Asset Type:</b> {event.resource_description}
        </p>
        <p>
          <b>OEE Description:</b> {event.oee_description}
        </p>
        <p>
          <b>OEE Category:</b> {event.oee_category}
        </p>
        <p>
          <b>OEE Code:</b> {event.oee_code}
        </p>
        {selectedOECodeTypeDescription === "Works Order (WO)" && (
          <>
            <p>
              <b>WO Number:</b> {WONumber}
            </p>
            <p>
              <b>Build status:</b> {event.good_bad}
            </p>
          </>
        )}
        <p>
          <b>Start Date and Time:</b>{" "}
          {eventDialog?.time_start
            ? getLocalFormattedDate(event.time_start)
            : ""}
        </p>
        {endDateTime && (
          <p>
            <b>End Date and Time:</b>{" "}
            {event.time_finish ? getLocalFormattedDate(event.time_finish) : ""}
          </p>
        )}
        <p>
          <b>Duration:</b> {getDuration(event.time_start, event.time_finish)}
        </p>
        <p>
          <b>Comment:</b> {event.event_description}
        </p>
      </div>
    );
  }

  function saveData() {
    if (selectedOEECode) {

      setEvent({
        time_finish:
          eventDialog!.status === EVENT_ACTIONS.START
            ? eventDialog!.time_finish
            : endDateTime
              ? formatDateToISO(dayjs(endDateTime).utc().toDate())
              : "",
        resource_description: eventDialog!.resource_description,
        oee_category: selectedOEECode.oee_category,
        resource_id: eventDialog!.resource_id,
        oee_code: selectedOEECode.oee_code,
        location: eventDialog!.location,
        oee_description: selectedOEECode.oee_description,
        wo_category: WONumber !== "" ? selectedOEECode.oee_code : "",
        time_start: formatDateToISO(eventDialog!.time_start),
        wo_number: WONumber !== "" ? WONumber : "",
        is_active: "True",
        resource: eventDialog!.resource,
        good_bad: WONumber !== "" ? WOGoodBad : "",
        event_description: comment ? comment : "",
      });
    }

  }

  async function handleAddNewEntryClick() {
    // update fields on event dialog
    if (selectedOEECode) {
      await putEvent({
        time_finish:
          eventDialog!.status === EVENT_ACTIONS.START
            ? formatDateToISO(eventDialog!.time_finish)
            : formatDateToISO(endDateTime)
              ? formatDateToISO(dayjs(endDateTime).utc())
              : "",
        resource_description: eventDialog!.resource_description,
        oee_category: selectedOEECode.oee_category,
        resource_id: eventDialog!.resource_id,
        oee_code: selectedOEECode.oee_code,
        location: eventDialog!.location,
        oee_description: selectedOEECode.oee_description,
        wo_category: WONumber !== "" ? selectedOEECode.oee_code : "",
        time_start: formatDateToISO(eventDialog!.time_start),
        wo_number: WONumber !== "" ? WONumber : "",
        is_active: "True",
        resource: eventDialog!.resource,
        good_bad: WONumber ? WOGoodBad : "",
        event_description: comment ? comment : "",
      });
    }

    handleClose();
  }

  function handleStopCode() {
    saveData();
    setShowSummary(true);
    setTitle("Resume");
    setStopCodeButtonStatus(false);
    setSaveButtonStatus(true);
  }

  async function stopCode() {
    await putEvent(event);
    handleClose();
  }

  function changeRadioButton(e: { target: { value: string } }) {
    setWOGoodBad(e.target.value);
  }

  // filter list of OEE Codes after OEE Code Type had been selected
  useEffect(() => {
    const filteredList = codeList.filter(
      (item) => item.type === selectedOECodeTypeDescription
    );
    setFilteredCodeList(filteredList);
  }, [selectedOECodeTypeDescription, codeList]);

  useEffect(() => {
    if (eventDialog) {
      setTitle(
        eventDialog.status !== null && eventDialog.status !== undefined
          ? eventDialog.status
          : ""
      );

      if (eventDialog.status === EVENT_ACTIONS.START) {
        setSelectedOEECodeTypeDescription("Works Order (WO)");
        setStartCodeButtonStatus(true);
      } else {
        const selectedCode = codeList.find(
          (item) => item.oee_code === eventDialog.oee_code
        );

        setSelectedOEECodeTypeDescription(selectedCode?.type);
        setSelectedOEECode(selectedCode);

        setComment(eventDialog.event_description);
        setWOGoodBad(eventDialog.good_bad ? eventDialog.good_bad : "good");
        setWONumber(eventDialog.wo_number);

        if (eventDialog.status === EVENT_ACTIONS.EDIT) {
          setSaveButtonStatus(true);
        } else if (eventDialog.status === EVENT_ACTIONS.DELETE) {
          setDiscardButtonStatus(true);
        } else if (eventDialog.status === EVENT_ACTIONS.STOP) {
          setStopCodeButtonStatus(true);
          setEndDateTime(dayjs().utc().toDate());
        }
      }
    }
  }, [codeList, codeTypes, eventDialog]);

  useEffect(() => {
    async function fetchData() {
      if (!selectedResource) return;

      // get the code possible code list for this resource location and cell
      const codes = await getCodesforLocationAndCelltype(
        selectedResource.location,
        selectedResource.cell
      );
      setCodeList(codes);

      // set unique code types for OEE Code Type Field
      const uniqueTypes = [
        ...new Set<any>(codes.map((item: { type: string }) => item.type)),
      ].map((type) => type.toString());
      setCodeTypes(uniqueTypes);
    }
    fetchData();
  }, [selectedResource]);

  return (
    <Dialog
      className="code-dialog"
      fullWidth
      maxWidth="xs"
      open={toogleDialog}
      onClose={handleClose}
    >
      {/* Title depends on the action being executed */}
      <DialogTitle>{title} OEE Code</DialogTitle>
      <div>{/* <p>{actionType}</p> */}</div>
      <DialogContent>
        {!showSummary ? (
          <>
            <div className="code-dialog__field">
              {/* ------- OEE Code Type Field --------~----------------------------------- */}
              <label>OEE Code Type</label>
              <select
                className="code-dialog__field--text"
                value={selectedOECodeTypeDescription}
                onChange={(e) => {
                  setSelectedOEECodeTypeDescription(e.target.value);
                  setSelectedOEECode(undefined);
                }}
              >
                {codeTypes.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            {/* ------- if selected "OEE Code Type" is Work Order open WO Type and get Regex for that field ------------------------- */}
            {selectedOECodeTypeDescription === "Works Order (WO)" ? (
              <>
                <div className="code-dialog__field">
                  <label>WO Type</label>
                  <select
                    className="code-dialog__field--text"
                    value={selectedOEECode?.oee_code}
                    onChange={(e) => {
                      let code = filteredCodeList.find(
                        (item) => item.oee_code === e.target.value
                      );
                      setSelectedOEECode(code ?? selectedOEECode);
                    }}
                  >
                    <option></option>
                    {filteredCodeList.map((option) => (
                      <option key={option.oee_code} value={option.oee_code}>
                        {option.oee_description.replace(" Works Order", "")}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedOEECode?.oee_code !== undefined && (
                  <div className="code-dialog__field">
                    <label>Value</label>{" "}
                    <span>
                      <b>{selectedOEECode?.oee_code}</b>
                    </span>
                    <input
                      className="code-dialog__field--text"
                      onChange={(e) => setWONumber(e.target.value)}
                      value={WONumber || ""}
                    ></input>
                  </div>
                )}

                {eventDialog?.status !== EVENT_ACTIONS.START ? (
                  <div className="code-dialog__field">
                    <label>How was the build?</label>
                    <div className="btn-group" role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        value="good"
                        onChange={changeRadioButton}
                        checked={WOGoodBad === "good"}
                      />
                      <label
                        className="btn btn--green btn-outline-primary"
                        htmlFor="btnradio1"
                      >
                        Good
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        onChange={changeRadioButton}
                        value="bad"
                        checked={WOGoodBad === "bad"}
                      />
                      <label
                        className="btn btn--red btn-outline-primary"
                        htmlFor="btnradio2"
                      >
                        Bad
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className="code-dialog__field">
                {/* ------- else just display the codes associated with "OEE Code Type" ------------------------- */}
                <label>OEE Code</label>
                <select
                  className="code-dialog__field--text"
                  value={selectedOEECode?.oee_code}
                  onChange={(e) => {
                    let code = filteredCodeList.find(
                      (item) => item.oee_code === e.target.value
                    );
                    setSelectedOEECode(code ?? selectedOEECode);
                  }}
                >
                  <option>-</option>
                  {filteredCodeList.map((option) => (
                    <option key={option.oee_code} value={option.oee_code}>
                      {option.oee_code} - {option.oee_description}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="code-dialog__field">
              <label>Start Date and Time</label>
              <label className="code-dialog__field--text">
                {eventDialog?.time_start
                  ? getLocalFormattedDate(eventDialog?.time_start)
                  : ""}
              </label>
            </div>

            {eventDialog?.status !== EVENT_ACTIONS.START ? (
              <div className="code-dialog__field">
                <label>End Date and Time</label>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    value={dayjs(endDateTime)}
                    onChange={(date) => {
                      setEndDateTime(date ? date.toDate() : null)
                    }
                    }
                    views={["year", "month", "day", "hours", "minutes"]}
                    format="ddd, DD MMMM HH:mm"
                    minDateTime={minDateTime.subtract(gmtOffsetMinutes, 'minute')}
                  />
                </LocalizationProvider>
              </div>
            ) : (
              ""
            )}
            <div className="code-dialog__field">
              <label>Comment</label>

              <textarea
                className="code-dialog__field--text"
                onChange={(e) => setComment(e.target.value)}
                value={comment ? comment : ""}
              ></textarea>
            </div>
          </>
        ) : (
          <Summary />
        )}
      </DialogContent>
      <DialogActions>
        {discardButtonStatus ? (
          <Button
            disabled={checkStartConfirmStatus()}
            label="Discard Code"
          // onClick={handleAddNewEntryClick}
          />
        ) : null}

        {startCodeButtonStatus ? (
          <Button
            disabled={checkStartConfirmStatus()}
            label="Start code"
            onClick={handleAddNewEntryClick}
          />
        ) : null}

        {stopCodeButtonStatus ? (
          <Button
            // disabled={isEditing}
            label="Save code"
            onClick={handleStopCode}
          />
        ) : null}

        {saveButtonStatus ? (
          <Button
            // disabled={isEditing}
            label="Confirm"
            onClick={stopCode}
          />
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

export default CodeDialog;
