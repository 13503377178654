import { NavLink } from "react-router-dom";
// import style from "./navItem.module.css";
import NavItemHeader from "./../navItemHeader/NavItemHeader";

import "./../SideNavbar.scss";

const NavItem = (props: {
  item: { label: string; icon?: string; url: string; children?: any };
}) => {
  const { label, url, children } = props.item;
  if (children) {
    return <NavItemHeader item={props.item} />;
  }
  return (
    <NavLink
      to={url}
      className="nav-item-header__item"
    //   activeClassName={style.activeNavItem}
    >
      {/* <Icon className={style.navIcon} /> */}
      <span>{label}</span>
    </NavLink>
  );
};

export default NavItem;
