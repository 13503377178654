import React, { useContext, useEffect, useRef, useState } from "react";
import { ResourceContext } from "../../store/ResourceContext";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { getLatestEventByResource } from "../../services/ResourcesEndpoints";

import {
  Event,
  getEventsByWeekAndResource,
} from "../../services/EventsEndpoints";
import "./OEEWeekDisplay.scss";

import { calculateDuration, getLocalFormattedDate } from "../../utils/Utils";
import { daysOfTheWeek, Schedule, TimeSlot, WeekDay } from "./OEEWeekDisplay.types";
import { generateTimeSlots, hours } from "./timeSlotHelper";
import OEEWeekDisplayCaption from "./OEEWeekDisplayCaption";
import dayjs from "dayjs";



const getMargin = (_timeSlot: TimeSlot, _index: number, _array: any[]) => {
  let currentIndex = _index + 1;
  const currentDuration = _timeSlot?.event?.duration;
  const currentSk = _timeSlot?.event?.created_at;
  const currentIndexDuration = _array[currentIndex]
  if (currentIndex < _array.length && currentIndexDuration?.event !== undefined) {
    return currentIndexDuration?.event?.duration === currentDuration &&
      currentIndexDuration?.event?.created_at === currentSk ? false : true
  }
}


const OEEWeekDisplay = () => {
  const resourceContext = useContext(ResourceContext);

  if (!resourceContext) {
    throw new Error("ResourceContext is null");
  }

  const { selectedLocation, selectedResource, selectedWeekNumber, categories } =
    resourceContext;

  const [events, setEvents] = useState<Event[]>([]);

  const [availableCategories, setAvailableCategories] = useState<any[]>([])

  const [schedule, setSchedule] = useState<Schedule>();
  const intervals = useRef<number[]>([]);
  const [scheduleEvents, setScheduleEvents] = useState<Schedule>();

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #dadde9",
      padding: theme.spacing(2),
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      "& h5": {
        fontWeight: "bold",
        margin: 0,
        paddingBottom: theme.spacing(2),
        fontSize: theme.typography.pxToRem(20),
      },
      "& p": {
        marginBottom: theme.spacing(1),
        fontSize: theme.typography.pxToRem(16),
      },
      "& .MuiTooltip-arrow": {
        color: "#fff", // Change the color of the arrow
        "&:before": {
          border: "1px solid #dadde9",
        },
      },
    },
  }));

  function getColorByCategory(category?: string) {
    const foundCategory = categories.find((c) => c?.oee_category === category);
    return foundCategory?.category_color || "#fff";
  }

  useEffect(() => {
    async function fetchData() {
      if (selectedResource && selectedWeekNumber.weekNumber) {
        const data = await getEventsByWeekAndResource(
          selectedLocation,
          selectedWeekNumber.weekNumber,
          selectedResource.resource_id
        );


        if (data && data.length) {
          let eventsUpdated = data.map((event: any) => {
            const startTime = new Date(event.time_start);
            let endTime = new Date(event.time_finish);
            let updatedEvent = { ...event }
            // check if the event is currently active (time_start and time_finish are equal)
            if (startTime.getTime() === endTime.getTime()) {

              // set the time_finish value to the current time
              endTime = new Date();
              updatedEvent = {
                ...event,
                time_finish: new Date().toISOString(),
                isStillRunning: true,
                // update duration
                duration: calculateDuration(startTime, endTime)
              }
            }
            return updatedEvent
          })

          // const selectedCategories = eventsUpdated?.map((event: any) => event.oee_category);

          const selectedCategories = eventsUpdated?.map((event: any) => {
            const oee_category = event.oee_category;
            const good_bad = event.good_bad;

            return {
              oee_category,
              good_bad
            }
          });

          setAvailableCategories(selectedCategories?.map((category: any) => {
            const data = categories.find(categoryDetails => categoryDetails.oee_category === category.oee_category)
            const dt = { ...data, 'good_bad': category.good_bad }
            return dt !== undefined ? dt : []
          }))

          setEvents(eventsUpdated);
        }

        if (data.length === 0) {
          const lastEvent = await getLatestEventByResource(
            selectedLocation,
            selectedResource.resource_id
          );

          if (lastEvent) {
            const startTime = new Date(lastEvent.time_start);
            let endTime = new Date(lastEvent.time_finish);

            let updatedEvent = { ...lastEvent }
            if (startTime.getTime() === endTime.getTime()) {

              // set the time_finish value to the current time
              endTime = new Date();
              updatedEvent = {
                ...lastEvent,
                time_finish: dayjs().format('YYYY-MM-DDTHH:mm[Z]'),
                isStillRunning: true,
                // update duration
                duration: calculateDuration(startTime, endTime)
              }
            }
            setEvents([updatedEvent]);
          }
        }

        const startDate = selectedWeekNumber.startDayOfWeek;

        setSchedule(
          daysOfTheWeek.reduce((acc, day, index) => {
            const date = new Date(startDate);
            date.setDate(startDate.getDate() + index);
            acc[day] = generateTimeSlots(date);
            return acc;
          }, {} as Schedule)
        );
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, selectedResource, selectedWeekNumber]);


  useEffect(() => {
    const currentIntervals = intervals.current;
    // loop through each event and set the corresponding TimeSlots in the schedule
    events.forEach((event) => {
      const startTime = new Date(event.time_start);
      const endTime = event?.isStillRunning ? new Date() : new Date(event.time_finish);
      // check if the event is currently active (time_start and time_finish are equal)
      if (event?.isStillRunning) {

        // set the time_finish value to the current time
        // add an interval to update the time_finish value every 10 minutes only if is running on the week
        const intervalId = setInterval(() => {
          const updatedSchedule = { ...schedule }
          if (updatedSchedule) {
            Object.keys(updatedSchedule).forEach((day) => {
              const daySchedule = updatedSchedule[day as WeekDay]; // type assertion
              // loop through each time slot in the day's schedule
              // @ts-ignore
              daySchedule.forEach((timeSlot): any => {
                // check if the event falls within the time slot
                if (
                  timeSlot.startTime >= startTime &&
                  timeSlot.endTime <= endTime
                ) {
                  const date = new Date().toISOString();
                  timeSlot.event = timeSlot.event?.isStillRunning ? { ...event, time_finish: date, duration: calculateDuration(startTime, new Date()) } : event;
                }
              });
            });
          }
          // @ts-ignore
          setScheduleEvents(updatedSchedule);
        }, 600000); // 10 minutes in milliseconds

        // store the interval ID to clear it later
        // @ts-ignore
        intervals.current.push(intervalId);
      }

      // loop through each day of the week in the schedule
      if (schedule) {
        Object.keys(schedule).forEach((day) => {
          const daySchedule = schedule[day as WeekDay]; // type assertion

          // loop through each time slot in the day's schedule
          daySchedule.forEach((timeSlot) => {
            // check if the event falls within the time slot
            if (
              timeSlot.startTime >= startTime &&
              timeSlot.endTime <= endTime
            ) {
              timeSlot.event = event;
            }
          });
        });
      }
    });

    setScheduleEvents(schedule);

    // clear all intervals when the component unmounts
    return () => {
      currentIntervals.forEach((intervalId) => clearInterval(intervalId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  return (
    <div className="oee-week-display">
      <div className="oee-week-display__timeline">
        {events.length === 0 && (
          <div className="oee-week-display__no-events">
            no events for this resource in the selected week
          </div>
        )}
        <div className='oee-week-display__hour-details'>
          <div className='oee-week-display__hour-interval'></div>
          {hours.map((hour) => (
            <div className='oee-week-display__hour-interval' key={hour}>
              {hour}
            </div>
          ))}
        </div>

        <div>
          {daysOfTheWeek.map((day) => (
            <div key={day} className='oee-week-display__day-details'>
              <div className="oee-week-display__day-interval">
                {day}
              </div>
              {scheduleEvents && categories &&
                scheduleEvents[day].map((timeSlot, index, array): any => (
                  <HtmlTooltip
                    key={timeSlot.startTime.toISOString()}
                    arrow
                    title={
                      timeSlot.event ? (
                        <React.Fragment>
                          <div>
                            <h5>Details</h5>
                            <p>
                              <b>OEE Category: </b>
                              {timeSlot.event.oee_category}
                            </p>
                            <p>
                              <b>OEE Description: </b>
                              {timeSlot.event.oee_description}
                            </p>
                            <p>
                              <b>OEE Code: </b>
                              {timeSlot.event.oee_code}
                            </p>
                            {timeSlot.event.oee_code.includes("WO") && (
                              <>
                                <p>
                                  <b>WO Number: </b>
                                  {timeSlot.event.wo_number}
                                </p>
                                <p>
                                  <b>Build status: </b>
                                  {timeSlot.event.good_bad}
                                </p>
                              </>
                            )}
                            <p>
                              <b>Date Start: </b>
                              {getLocalFormattedDate(timeSlot.event.time_start)}
                            </p>
                            <p>
                              <b>Date Finish:</b>
                              {getLocalFormattedDate(
                                timeSlot.event.time_finish
                              )}
                            </p>
                            <p>
                              <b>Comment: </b>
                              {timeSlot.event.event_description}
                            </p>
                            <p>
                              <b>Duration: </b>
                              {timeSlot.event.duration}
                            </p>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )
                    }
                  >
                    {timeSlot?.event?.oee_category !== undefined ?

                      <div
                        key={timeSlot?.startTime.toISOString()}
                        style={{
                          backgroundColor:
                            timeSlot?.event?.oee_category &&
                              getMargin(timeSlot, index, array) ? 'white' : getColorByCategory(timeSlot?.event?.oee_category),
                          width: "10px",
                          height: "40px",
                          position: 'relative',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          // marginRight: getMargin(timeSlot, index, array) ? '2px' : '0px'
                        }} >
                        {timeSlot?.event?.good_bad === 'bad' && !getMargin(timeSlot, index, array) ? <div style={{ zIndex: 1000 }}>|</div> : <></>}
                      </div>
                      : <></>}
                  </HtmlTooltip>
                ))}
            </div>
          ))}
        </div>
      </div>
      <OEEWeekDisplayCaption data={availableCategories} />
    </div>
  );
};

export default OEEWeekDisplay;
