import dayjs from "dayjs";

export const calculateDuration = (startTime: Date, endTime: Date): string => {
  const diffInMs = endTime.getTime() - startTime.getTime();
  const diffInMinutes = Math.floor(diffInMs / 1000 / 60);
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  const hoursStr = hours.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  return `${hoursStr}:${minutesStr}`;
};

export function convertToHours(
  durationInMinutes: number,
  includeMinutes = true
) {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  if (includeMinutes) {
    return `${hours} h ${minutes} min`;
  } else {
    return `${hours} hour(s)`;
  }
}

function getGmtOffsetInMinutes(dateString: any) {
  const now = dayjs();
  // Calculate the GMT offset in minutes
  const gmtOffsetMinutes = now.utcOffset();
  return gmtOffsetMinutes / 60;
}

export function getLocalFormattedDate(date: string) {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let dateString = new Date(date);

  return dateString.toLocaleString("en-GB", {
    timeZone: timezone,
    hour12: false, // Set to false to use 24-hour format
    hour: "numeric",
    minute: "numeric",
    weekday: "short",
    day: "numeric",
    month: "long",
  });
}

export function getLocalFormattedforDifference(date: string): string {
  // Resolve the local time zone
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a Date object from the provided date string
  const dateObject = new Date(date);

  // Convert to the local time format
  const localTimeString = dateObject.toLocaleString("en-GB", {
    timeZone: timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Use 24-hour format
  });

  // Reformat the local time string to match the desired format
  const [day, month, year, hour, minute] = localTimeString
    .replace(",", "")
    .split(/[\s:/]/);

  return `${year}-${month}-${day}T${hour}:${minute}`;
}





export function formatDateToISO(dateString: any) {
  // Convert to Date object
  let date = new Date(dateString);

  // Format to the desired string format
  let formattedDate = date.toISOString().slice(0, 16) + "Z";

  return formattedDate;
}


export function getLocalFormattedEndDate(date: string) {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let dateString = new Date(date);
  let gmtOffsetInMinutes = getGmtOffsetInMinutes(dateString);
  // Subtract one hour
  dateString.setHours(dateString.getHours() - gmtOffsetInMinutes);

  return dateString.toLocaleString("en-GB", {
    timeZone: timezone,
    hour12: false, // Set to false to use 24-hour format
    hour: "numeric",
    minute: "numeric",
    weekday: "short",
    day: "numeric",
    month: "long",
  });
}