

import { Box } from "@mui/material";

interface iTextField {
    value: string;
}

function NonEditText({ value }: iTextField) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                '& > :not(style)': { m: 1, },
            }}
        >
            <p style={{ textAlign: 'center', color: 'lightgrey' }}>
                {value}
            </p>
        </Box>
    );
}

export default NonEditText;
