import { getData, putData, deleteData } from "./Api";
import { CONSTANTS } from "../configs/constants";
import { getTypes } from "./TypesEndpoints";

export interface Code {
  locations: string[];
  oee_code: string;
  oee_description: string;
  type_code: string;
  SK: string;
  oee_category: string;
  is_active: boolean;
  PK: string;
  cell: string;
  type: string;
}

export const codesEndpoints = {
  get: async () => await getCodes(),
  put: async (data: any) => await putCodes(data),
  delete: async (data: string) => await deleteCodes(data),
};

export async function getCodes() {
  const url = process.env.REACT_APP_BACKEND_URL + CONSTANTS.CODES;

  return await getData(url);
}

export async function putCodes(data: any) {
  const url =
    process.env.REACT_APP_BACKEND_URL + CONSTANTS.CODES + "/" + data.oee_code;

  // transform string locations into an array
  // data.locations = data.locations.split(",").map((str: string) => str.trim());
  // get type description for the selected Type Code
  const types = await getTypes();

  const selectedType = types.find(
    (item: { type_code: any }) => item.type_code === data.type_code
  );

  data.type = selectedType.type;
  return await putData(url, data);
}

export async function deleteCodes(data: any) {
  const url =
    process.env.REACT_APP_BACKEND_URL + CONSTANTS.CODES + "/" + data.oee_code;

  return await deleteData(url);
}

// -------------- ADDITIONAL ENDPOINTS ----------------
export async function getCodesforLocationAndCelltype(
  location: string,
  cell: string
) {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    CONSTANTS.LOCATIONS +
    "/" +
    location +
    "/" +
    CONSTANTS.CODES;
  const data = await getData(url);

  const filteredData = data?.filter(
    (item: { cell: string | Array<string> }) => {
      return Array.isArray(item.cell) ? item.cell.includes(cell) : item.cell === cell;
    }
  );

  return filteredData;
}
