import { getData, putData, deleteData } from "./Api";
import { CONSTANTS } from "../configs/constants";

export const userEndpoints = {
  get: async (user: any) => await getUsers(user),
  put: async (data: any) => await putUsers(data),
  delete: async (data: string) => await deleteUsers(data),
};

export async function getUser(gid: string) {
  const url = process.env.REACT_APP_BACKEND_URL + CONSTANTS.USERS + "/" + gid;

  return await getData(url);
}

export async function getUsers(user: any) {
  const url = process.env.REACT_APP_BACKEND_URL + CONSTANTS.USERS;

  const data = await getData(url);

  return data;
}

export async function putUsers(data: any) {
  if (!data.gid) {
    throw new Error("Missing gid");
  }

  const url =
    process.env.REACT_APP_BACKEND_URL + CONSTANTS.USERS + "/" + data.gid;

  // if no user_location is set, set it to the user's location
  if (!data.user_locations || data.user_locations.length === 0) {
    data.user_locations = [data.location];
  } else {
    // data.user_locations = data.user_locations.split(",");
    // data.user_locations = data.user_locations
    //   .split(",")
    //   .map((str: string) => str.trim());
  }
  return await putData(url, data);
}

export async function deleteUsers(data: any) {
  // transform user into api format
  const url =
    process.env.REACT_APP_BACKEND_URL + CONSTANTS.USERS + "/" + data.gid;
  return await deleteData(url);
}
